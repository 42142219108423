import { FetchResourceCollectionOK } from './types/FetchResourceCollectionOK';
import { FetchResourceOK } from './types/FetchResourceOK';
import { Resource } from './types/Resource';

// eslint-disable-next-line
export default <T extends Resource>(japiDocument: FetchResourceOK<any> | FetchResourceCollectionOK<any>, resource: Resource, property: string): T[] | undefined => {
    if (!japiDocument.included) {
        return undefined;
    }

    if (!resource.relationships || Array.isArray(resource.relationships)) {
        return undefined;
    }

    if (!resource.relationships[property]) {
        return undefined;
    }

    const relationship = resource.relationships[property];

    if (!relationship.data || !Array.isArray(relationship.data)) {
        return undefined;
    }

    const resourceArray: T[] = [];

    relationship.data.forEach(object => {
        const resourceElement = japiDocument.included
            ? japiDocument.included.find((el) => el.type === object.type && el.id === object.id) : null;

        if (resourceElement) {
            resourceArray.push(resourceElement as T);
        }
    });

    return resourceArray;
};
