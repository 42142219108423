import { FC } from 'react';

import { Button } from '../../../components';
import { BackButton } from '../../../compositions';
import trans from '../../../helpers/trans';
import { MealType } from '../../../types';

import './MealScreen.scss';

interface MealScreenProps {
    showBackButton?: boolean;
    firstName?: string;
    onBackButtonClick: () => void;
    onMealButtonClick: (pause: MealType) => void;
    mealTypes: MealType[];
    className?: string;
}

const MealScreen: FC<MealScreenProps> = ({
    showBackButton,
    firstName,
    onBackButtonClick,
    onMealButtonClick,
    mealTypes,
    className = '',
}) => (
    <div className={`meal-screen ${className}`}>
        <h1>{firstName}</h1>
        <h1 className="meal-screen__title">
            {trans('containers.mealScreen.title')}
        </h1>
        <div className="meal-screen__buttons-wrapper">
            {mealTypes.map(mealType => (
                <Button
                    key={mealType}
                    text={trans(`types.mealType.${mealType.toLowerCase()}`)}
                    onClick={() => onMealButtonClick(mealType)}
                    className="meal-screen__meal-option"
                />
            ))}
        </div>
        {showBackButton && (
            <BackButton
                onClick={onBackButtonClick}
                className="meal-screen__back-button"
            />
        )}
    </div>
);

export default MealScreen;
