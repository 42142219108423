import { Department } from '../Department/Department';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftPlanning } from '../ShiftPlanning/ShiftPlanning';
import { TemporaryWorker } from '../TemporaryWorker/TemporaryWorker';
import { UserResource } from '../User/User';
import { transformToUser } from '../User/UserTransformers';
import { Shift, ShiftIndex, ShiftResource } from './Shift';

export const transformToShiftIndex = (
    shiftResource: ShiftResource,
    department: Department,
    shiftPlannings: ShiftPlanning[],
    temporaryWorkers?: TemporaryWorker[],
    previouslyPlannedUsersResource?: UserResource[],
): ShiftIndex => ({
    id: shiftResource.id,
    peopleLimit: shiftResource.attributes.peopleLimit,
    department,
    period: transformToPeriod(
        new Date(shiftResource.attributes.start),
        new Date(shiftResource.attributes.end),
    ),
    shiftPlannings,
    temporaryWorkers: temporaryWorkers || [],
    previouslyPlannedUsers: previouslyPlannedUsersResource ? previouslyPlannedUsersResource.map(userResource => transformToUser(userResource)) : [],
});

export const transformToShift = (
    shiftResource: ShiftResource,
    department: Department,
): Shift => ({
    id: shiftResource.id,
    peopleLimit: shiftResource.attributes.peopleLimit,
    department,
    period: transformToPeriod(
        new Date(shiftResource.attributes.start),
        new Date(shiftResource.attributes.end),
    ),
});

export const transformShiftsDomStringToShifts = (domString: string): ShiftIndex[] => {
    const shifts = JSON.parse(domString) as ShiftIndex[];

    if (!Array.isArray(shifts)) {
        return [];
    }

    // After parsing a string to an object all dates are still strings. So we need to transform all dates here.
    return shifts.map(shift => {
        const shiftPlannings = shift.shiftPlannings.map(shiftPlanning => ({
            ...shiftPlanning,
            period: transformToPeriod(
                new Date(shiftPlanning.period.start),
                new Date(shiftPlanning.period.end),
            ),
        }));

        return {
            ...shift,
            shiftPlannings,
            period: transformToPeriod(
                new Date(shift.period.start),
                new Date(shift.period.end),
            ),
        };
    });
};
