import { FC, ReactElement, useMemo } from 'react';

import classnames from 'classnames';

import { NumpadFunctionType } from '../../../../types';
import { getNumpadIcon } from '../../helpers/getNumpadIcon';

import './NumpadButton.scss';

interface NumpadButtonProps {
    value: number | NumpadFunctionType;
    onKeyUp: (value: number | NumpadFunctionType) => void;
    className?: string;
}

const NumpadButton: FC<NumpadButtonProps> = ({ value, onKeyUp, className = '' }): ReactElement => {
    const element = useMemo(() => getNumpadIcon(value), [value]);

    const buttonClassName = classnames('numpad-button', {
        [`numpad-button--is-${value}`]: value,
    }, className);

    const handleButtonKeyUp = (): void => {
        onKeyUp(value);
    };

    return (
        <button
            type="button"
            onClick={handleButtonKeyUp}
            className={buttonClassName}
        >
            {element}
        </button>
    );
};

export default NumpadButton;
