import { FC, ReactNode, ReactPortal } from 'react';

import { createPortal } from 'react-dom';

interface RootPortalProps {
    children?: ReactNode;
}

const RootPortal: FC<RootPortalProps> = ({ children }): ReactPortal | null => {
    const portalRoot = document.getElementById('app-root');

    return portalRoot && createPortal(
        children,
        portalRoot,
    );
};

export default RootPortal;
