import {
    CompanyMedicType,
    GenderType,
    IdentificationType,
} from '../../types';
import {
    BasicPerson,
    Person,
    PersonResource,
} from './Person';

export const transformToBasicPerson = (
    firstname: string,
    lastname: string,
    affix = '',
): BasicPerson => ({
    firstname,
    affix,
    lastname,
});

export const transformToPerson = (resource: PersonResource): Person => ({
    id: resource.id,
    firstname: resource.attributes.firstname,
    affix: resource.attributes.affix,
    lastname: resource.attributes.lastname,
    gender: resource.attributes.gender as GenderType,
    birthday: resource.attributes.birthday ? new Date(resource.attributes.birthday) : undefined,
    hasSundayAllowance: resource.attributes.hasSundayAllowance,
    identificationExpirationDate: new Date(resource.attributes.identificationExpirationDate),
    identificationType: resource.attributes.identificationType as IdentificationType,
    companyMedic: resource.attributes.isCompanyMedic || CompanyMedicType.unknown,
    nationality: resource.attributes.nationality,
    workDays: resource.attributes.workDays,
});

export const transformCompanyMedicType = (resource: number): CompanyMedicType => {
    if (resource === 2) {
        return CompanyMedicType.yes;
    }

    if (resource === 1) {
        return CompanyMedicType.no;
    }

    return CompanyMedicType.unknown;
};
