import { FC } from 'react';

import trans from '../../../helpers/trans';
import { IconButton } from '../../index';

import './BackButton.scss';

interface BackButtonProps {
    onClick?: () => void;
    className?: string;
}

const BackButton: FC<BackButtonProps> = ({ onClick, className = '' }) => (
    <IconButton
        icon="chevron-left"
        text={trans('common.back')}
        onClick={onClick}
        className={`back-button ${className}`}
        iconClassName="back-button__icon"
    />
);

export default BackButton;
