import { generateApiUrl } from '../../helpers/url';
import { Ping, PingResource } from './Ping';
import { transformToPing } from './PingTransformers';

export const pingApiCall = async (abortTimeout: number): Promise<Ping | undefined> => (
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve) => {
        const pingApiCallAbortController = new AbortController();

        const timeoutId = setTimeout((): void => {
            pingApiCallAbortController.abort();
            resolve(undefined);
        }, abortTimeout);

        try {
            const url = generateApiUrl({
                endpoint: '/api/v2/ping',
            });

            const response = await fetch(url, { signal: pingApiCallAbortController.signal });
            const doc: { data: PingResource } = await response.json();

            if (!doc || !doc.data) {
                resolve(undefined);

                return;
            }

            const ping = transformToPing(doc.data);

            resolve(ping);
        } catch (e) {
            resolve(undefined);
        } finally {
            clearTimeout(timeoutId);
        }
    })
);

