export const isNewerVersion = (latestVersion: string, compareVersion: string): boolean => {
    const latestParts = latestVersion.split('.');
    const compareParts = compareVersion.split('.');

    for (let i = 0; i < latestParts.length; i += 1) {
        const latestPart = ~~latestParts[i]; // eslint-disable-line no-bitwise
        const comparePart = ~~compareParts[i]; // eslint-disable-line no-bitwise

        if (latestPart > comparePart) return true;
        if (latestPart < comparePart) return false;
    }

    return false;
};

export const clearCaches = async (): Promise<void> => {
    if (caches) {
        await caches.keys().then(names => {
            for (let i = 0; i < names.length; i += 1) {
                caches.delete(names[i]);
            }
        });
    }

    window.location.reload();
};
