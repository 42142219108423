import { useMemo } from 'react';

import { TimerUser } from '../../entities/User/User';
import { useTypedSelector } from '../../store';

const useTimerUser = (employeeNumber: number): TimerUser | undefined => {
    const users = useTypedSelector(state => state.usersReducer.users);

    return useMemo(() => users.find(user => user.employeeNumber === employeeNumber), [employeeNumber, users]);
};

export default useTimerUser;
