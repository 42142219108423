import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import { LoadingScreen } from '../../../components';
import { NumpadInput } from '../../../compositions';
import Dots from '../../../compositions/Dots/Dots';
import trans from '../../../helpers/trans';
import { NumpadFunctionType } from '../../../types';
import { Page } from '../../index';

import './MasterLoginScreen.scss';

interface MasterLoginScreenProps {
    isFailed: boolean;
    isLoading: boolean;
    onSubmitMasterCode: (masterCode: string) => void;
    className?: string;
}

const keyLength = 10;

const MasterLoginScreen: FC<MasterLoginScreenProps> = ({
    isFailed,
    isLoading,
    onSubmitMasterCode,
    className = '',
}): ReactElement => {
    const [adminKey, setAdminKey] = useState('');

    const handleNumpadButtonKeyUp = (value: number | NumpadFunctionType): void => {
        if (value === NumpadFunctionType.submit) {
            return;
        }

        if (value === NumpadFunctionType.backspace) {
            setAdminKey(adminKey.substring(0, adminKey.length - 1));

            return;
        }

        if (adminKey.length === keyLength) {
            return;
        }

        setAdminKey(`${adminKey}${value}`);
    };

    useEffect((): void => {
        if (adminKey.length < keyLength) {
            return;
        }

        onSubmitMasterCode(adminKey);
    }, [adminKey]);

    useEffect(() => {
        if (isFailed) {
            setAdminKey('');
        }
    }, [isFailed]);

    if (isLoading) {
        return (
            <Page>
                <LoadingScreen />
            </Page>
        );
    }

    const screenClassName = classNames('master-login-screen', {
        'master-login-screen--is-failed': isFailed,
    }, className);

    return (
        <Page className={screenClassName}>
            <div className="master-login-screen__wrapper">
                <h1 className="master-login-screen__title">
                    {trans(`containers.masterLoginScreen.${isFailed ? 'wrongMasterCode' : 'title'}`)}
                </h1>

                <Dots
                    amount={keyLength}
                    filledAmount={adminKey.length}
                    className="master-login-screen__dots"
                />

                <NumpadInput
                    onButtonKeyUp={handleNumpadButtonKeyUp}
                    className="master-login-screen__numpad-input"
                />
            </div>
        </Page>
    );
};

export default MasterLoginScreen;
