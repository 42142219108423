import { FC, ReactElement } from 'react';

import { Timer } from '../../containers';
import { useTypedSelector } from '../../store';
import { UserStatus } from '../../types/userStatus';

const ConnectedTimer: FC = (): ReactElement => {
    const { isOnline } = useTypedSelector(state => state.appReducer);
    const { employeeNumber, pin, authenticatedUser } = useTypedSelector(state => state.userReducer);
    const { trackRecords } = useTypedSelector(state => state.trackRecordsReducer);
    const { tracks } = useTypedSelector(state => state.tracksReducer);
    const { shifts } = useTypedSelector(state => state.shiftsReducer);

    return (
        <Timer
            isOffline={!isOnline}
            isUserInactive={authenticatedUser?.status === UserStatus.inActive}
            employeeNumber={employeeNumber}
            pin={pin}
            shifts={shifts}
            trackRecords={trackRecords}
            tracks={tracks}
        />
    );
};

export default ConnectedTimer;
