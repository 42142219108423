import { differenceInMinutes } from '../../helpers/date';
import { CheckInRecord, CheckOutRecord } from './TrackRecord';

export const isCheckInRecord = (record: CheckInRecord | CheckOutRecord): record is CheckInRecord => (
    'startDate' in record
    && !('endDate' in record)
);

export const isCheckOutRecord = (record: CheckInRecord | CheckOutRecord): record is CheckOutRecord => (
    'startDate' in record
    && 'endDate' in record
);

export const getTrackRecordTotalWorkedHours = (record: CheckInRecord | CheckOutRecord): number => {
    if (!isCheckOutRecord(record)) {
        return 0;
    }

    return differenceInMinutes(new Date(record.endDate), new Date(record.startDate)) / 60;
};

export interface TrackRecordResponse {
    status: number;
    messages: (CheckInRecord | CheckOutRecord)[];
}
