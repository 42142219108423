import { FC } from 'react';

import { Button } from '../../../components';
import { TimerPauseDurationOption } from '../../../entities/Setting/Setting';
import trans from '../../../helpers/trans';

import './PauseScreen.scss';

interface PauseScreenProps {
    firstName?: string;
    pauseDurationOptions: TimerPauseDurationOption[];
    onPauseDurationOptionsClick: (pause: number) => void;
    className?: string;
}

const PauseScreen: FC<PauseScreenProps> = ({
    firstName,
    pauseDurationOptions,
    onPauseDurationOptionsClick,
    className = '',
}) => (
    <div className={`pause-screen ${className}`}>
        <h1>{firstName}</h1>
        <h1 className="pause-screen__title">
            {trans('containers.pauseScreen.title')}
        </h1>
        <div className="pause-screen__buttons-wrapper">
            {pauseDurationOptions.map(option => (
                <Button
                    key={option.id}
                    text={option.value.toString()}
                    onClick={() => onPauseDurationOptionsClick(option.value)}
                    className="pause-screen__button"
                />
            ))}
        </div>
    </div>
);

export default PauseScreen;
