import { FC, ReactElement, useMemo } from 'react';

import { getDots } from './helpers/getDots';
import { Dot } from './subcomponents';

import './Dots.scss';

interface DotsProps {
    amount: number;
    filledAmount: number;
    className?: string;
}

export interface DotData {
    id: string;
    isFilled: boolean;
}

const Dots: FC<DotsProps> = ({ amount, filledAmount, className = '' }): ReactElement => {
    const dots = useMemo(() => getDots(amount, filledAmount), [amount, filledAmount]);

    return (
        <div className={`dots ${className}`}>
            {dots.map(dot => (
                <Dot
                    key={dot.id}
                    isFilled={dot.isFilled}
                    className="dots__dot"
                />
            ))}
        </div>
    );
};

export default Dots;
