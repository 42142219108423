import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
    AnyAction,
    applyMiddleware,
    CombinedState,
    createStore,
    Store,
    StoreEnhancer,
} from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import reducers, { Reducers } from './redux/reducers';

export type TypedDispatch = ThunkDispatch<Reducers, unknown, AnyAction>;
export type ReducerGetter = () => Reducers;

export const useTypedDispatch = (): TypedDispatch => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<Reducers> = useSelector;

const createEnhancer = (): StoreEnhancer => (process.env.REACT_APP_APP_ENV !== 'production'
    ? composeWithDevToolsDevelopmentOnly(applyMiddleware(thunk))
    : applyMiddleware(thunk)
);

export const configureStore = async (preloadedState: Record<string, unknown> = {}): Promise<Store<CombinedState<Reducers>>> => {
    const enhancer = await createEnhancer();

    return createStore(reducers, preloadedState, enhancer);
};
