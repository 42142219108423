import { differenceInSeconds } from './date';

export const getTwoDigitsOfNumber = (digit: number): string => (`${digit}`.length > 1 ? digit.toString() : (`0${digit}`));

export const getHoursMinutesSecondsFromDate = (date: Date, serverTime: Date, timeLapsed = 0): { h: number, m: number, s: number } => {
    const totalSeconds = Math.max(0, differenceInSeconds(serverTime, date)) + timeLapsed;

    const h = Math.floor(totalSeconds / 3600);
    const m = Math.floor((totalSeconds % 3600) / 60);
    const s = Math.floor((totalSeconds % 3600) % 60);

    return { h, m, s };
};
