import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftIndexUserResource } from '../User/User';
import { transformToShiftPlanningUser } from '../User/UserTransformers';
import { ShiftPlanning, ShiftPlanningResource } from './ShiftPlanning';

export const transformToShiftPlanning = (
    resource: ShiftPlanningResource,
    userResource: ShiftIndexUserResource,
): ShiftPlanning => ({
    id: resource.id,
    type: resource.type,
    planned: resource.attributes.planned,
    customStartTime: resource.attributes.customStartTime,
    period: transformToPeriod(
        new Date(resource.attributes.start),
        new Date(resource.attributes.end),
    ),
    user: transformToShiftPlanningUser(userResource),
});
