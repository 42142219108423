import { useLocalStorage } from 'usehooks-ts';

import { CheckInRecord } from '../../../entities/TrackRecord/TrackRecord';
import { isCheckOutRecord } from '../../../entities/TrackRecord/TrackRecordHelpers';
import { localStorageApiTokenKey } from '../../../helpers/authorizedFetch';
import { addCheckOutTrackRecord, updateLastCheckOutRecord } from '../../../redux/trackRecords/trackRecordsActions';
import { logout } from '../../../redux/user/userReducer';
import { useTypedDispatch } from '../../../store';
import { HappinessType, MealType } from '../../../types';

interface UseClockOutHandlersProps {
    isOnline: boolean;
    employeeNumber: number;
    lastUserRecord?: CheckInRecord;
    shiftId?: string;
    serverApiDateTime: string;
    startApiDateTime: string;
    trackId?: string;
}

interface UserClockOutHandlersResult {
    onClockOutButtonClick: () => void;
    onHappinessClick: (happiness?: HappinessType) => void;
    onLogOutButtonClick: () => void;
    onPauseDurationClick: (pause?: number) => void;
    onMealClick: (meal?: MealType) => void;
    onSubmitStartedWithoutShiftComment: (unplannedRemark?: string) => void;
    onSubmitTrackDeviationRemark: (deviationRemark?: string) => void;
}

export const useClockOutHandlers = ({
    isOnline,
    employeeNumber,
    lastUserRecord,
    serverApiDateTime,
    shiftId,
    startApiDateTime,
    trackId,
}: UseClockOutHandlersProps): UserClockOutHandlersResult => {
    const dispatch = useTypedDispatch();
    const [, storeApiToken] = useLocalStorage<string | undefined>(localStorageApiTokenKey, undefined);

    const handleClockOutButtonClick = (): void => {
        dispatch(addCheckOutTrackRecord(
            !isOnline,
            employeeNumber,
            startApiDateTime,
            serverApiDateTime,
            shiftId,
            trackId,
        ));
    };

    const handlePauseDuration = (pause?: number): void => {
        if (lastUserRecord && isCheckOutRecord(lastUserRecord)) {
            dispatch(updateLastCheckOutRecord({ ...lastUserRecord, pause }));
        }
    };

    const handleHappinessClick = (happiness?: HappinessType): void => {
        if (lastUserRecord && isCheckOutRecord(lastUserRecord)) {
            dispatch(updateLastCheckOutRecord({ ...lastUserRecord, happiness }));
        }
    };

    const handleMealClick = (meal?: MealType): void => {
        if (lastUserRecord && isCheckOutRecord(lastUserRecord)) {
            dispatch(updateLastCheckOutRecord({ ...lastUserRecord, meal }));
        }
    };

    const handleSubmitStartedWithoutShiftComment = (unplannedRemark?: string): void => {
        if (lastUserRecord && isCheckOutRecord(lastUserRecord)) {
            dispatch(updateLastCheckOutRecord({ ...lastUserRecord, unplannedRemark }));
        }
    };

    const handleSubmitTrackDeviationRemark = (deviationRemark?: string): void => {
        if (lastUserRecord && isCheckOutRecord(lastUserRecord)) {
            dispatch(updateLastCheckOutRecord({ ...lastUserRecord, deviationRemark }));
        }
    };

    const handleLogOutButtonClick = (): void => {
        storeApiToken(undefined);
        dispatch(logout());
    };

    return {
        onClockOutButtonClick: handleClockOutButtonClick,
        onHappinessClick: handleHappinessClick,
        onLogOutButtonClick: handleLogOutButtonClick,
        onPauseDurationClick: handlePauseDuration,
        onMealClick: handleMealClick,
        onSubmitStartedWithoutShiftComment: handleSubmitStartedWithoutShiftComment,
        onSubmitTrackDeviationRemark: handleSubmitTrackDeviationRemark,
    };
};
