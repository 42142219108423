export enum FetchResultType {
    Error = 'error',
    Success = 'success',
}

export interface SuccessfulFetchResult<T> {
    status: number;
    type: FetchResultType.Success;
    data: T;
    totalPages?: number;
}

export interface SuccessfulFetchCollectionResult<T> {
    status: number;
    type: FetchResultType.Success;
    data: T;
    amountOfPages: number;
}

export interface UnsuccessfulFetchResult<E> {
    status: number;
    type: FetchResultType.Error;
    error: E;
}

export type FetchResult<T, E> = SuccessfulFetchResult<T> | UnsuccessfulFetchResult<E>;

export const isFetchResult = <T, E>(resource: any): resource is FetchResult<T, E> => (
    typeof resource === 'object'
    && 'status' in resource
    && 'type' in resource
);
export const isFetchResultSuccessful = <T, E>(result: FetchResult<T, E>): result is SuccessfulFetchResult<T> => (
    result.type === FetchResultType.Success
);

export type FetchCollectionResult<T, E> = SuccessfulFetchCollectionResult<T> | UnsuccessfulFetchResult<E>;

export const isFetchCollectionResultSuccessful = <T, E>(result: FetchCollectionResult<T, E>): result is SuccessfulFetchCollectionResult<T> => (
    result.type === FetchResultType.Success
);
