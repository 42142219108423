import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';
import { ClockedInTrack } from '../../entities/Track/Track';
import { transformTacksDomStringToClockedInTracks } from '../../entities/Track/TrackTransformers';
import { Error } from '../../japi/types/Error';

const localStorageTracksKey = 'tracks';
const localStorageTracksDomString = localStorage.getItem(localStorageTracksKey);
const localStorageTracks = localStorageTracksDomString ? transformTacksDomStringToClockedInTracks(localStorageTracksDomString) : [];

export type TracksState = AsyncReduxState<{
    tracks: ClockedInTrack[];
}>;

const initialState: TracksState = {
    ...initialAsyncReduxState,
    tracks: localStorageTracks,
};

export const TracksSlice = createSlice({
    name: 'tracksReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): TracksState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setTracks(state, action: PayloadAction<ClockedInTrack[]>): TracksState {
            localStorage.setItem(localStorageTracksKey, JSON.stringify(action.payload));

            return {
                ...state,
                tracks: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error>): TracksState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setTracks,
    setError,
} = TracksSlice.actions;

export default TracksSlice.reducer;
