import { FC, ReactElement } from 'react';

import { LoadingScreen } from '../../components';
import LoginScreen from '../../containers/@screens/LoginScreen/LoginScreen';
import { localStorageApiTokenKey } from '../../helpers/authorizedFetch';
import useLocalStorage from '../../helpers/hooks/useLocalStorage';
import { offlineLogin, onlineLogin } from '../../redux/user/userActions';
import { useTypedDispatch, useTypedSelector } from '../../store';

const ConnectedLoginScreen: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isOnline } = useTypedSelector(state => state.appReducer);
    const { isLoading, isUserNotFound } = useTypedSelector(state => state.userReducer);
    const [, storeApiToken] = useLocalStorage<string | undefined>(localStorageApiTokenKey, undefined);

    const handleSubmit = async (employeeNumber: number, pin: string): Promise<void> => {
        if (!isOnline) {
            dispatch(offlineLogin(employeeNumber, pin));

            return;
        }

        dispatch(onlineLogin(employeeNumber, pin, storeApiToken));
    };

    if (isLoading) {
        return (
            <LoadingScreen />
        );
    }

    return (
        <LoginScreen
            isUserNotFound={isUserNotFound}
            onSubmit={handleSubmit}
        />
    );
};

export default ConnectedLoginScreen;
