import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

export type MasterCodeState = AsyncReduxState<{
    isAuthenticated: boolean;
    isFailed: boolean;
}>;

const localStorageIsAuthenticatedKey = '5evKTOfMzF0tBrvkQ7Fp';

const initialState: MasterCodeState = {
    ...initialAsyncReduxState,
    isAuthenticated: localStorage.getItem(localStorageIsAuthenticatedKey) === 'true',
    isFailed: false,
};

export const MasterCodeSlice = createSlice({
    name: 'masterCodeReducer',
    initialState,
    reducers: {
        setIsAuthenticated(state, action: PayloadAction<boolean>): MasterCodeState {
            localStorage.setItem(localStorageIsAuthenticatedKey, action.payload.toString());

            return {
                ...state,
                isAuthenticated: action.payload,
            };
        },
        setIsFailed(state, action: PayloadAction<boolean>): MasterCodeState {
            return {
                ...state,
                isFailed: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): MasterCodeState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
    },
});

export const {
    setIsAuthenticated,
    setIsFailed,
    setIsLoading,
} = MasterCodeSlice.actions;

export default MasterCodeSlice.reducer;
