import * as Sentry from '@sentry/browser';

import trans from '../helpers/trans';
import { FetchResult, FetchResultType } from './FetchResult';

export const getCurrentVersion = async (): Promise<FetchResult<string, string>> => {
    try {
        const currentVersionResponse = await fetch(`${process.env.PUBLIC_URL}/version-current.json`, {
            headers: { 'Cache-Control': 'no-cache' },
        });

        const currentVersionJson = await currentVersionResponse.json();
        const currentVersion = currentVersionJson.version;

        return {
            status: 200,
            type: FetchResultType.Success,
            data: currentVersion,
        };
    } catch (error) {
        console.error('[getCurrentVersion]', error);

        Sentry.captureException(error, {
            extra: {
                source: 'getCurrentVersion',
                tenant: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getLatestVersion = async (): Promise<FetchResult<string, string>> => {
    try {
        const latestVersionResponse = await fetch(`${process.env.PUBLIC_URL}/version-latest.json`, {
            headers: { 'Cache-Control': 'no-cache' },
        });

        const latestVersionJson = await latestVersionResponse.json();
        const latestVersion = latestVersionJson.version;

        return {
            status: 200,
            type: FetchResultType.Success,
            data: latestVersion,
        };
    } catch (error) {
        console.error('[getLatestVersion]', error);

        Sentry.captureException(error, {
            extra: {
                source: 'getLatestVersion',
                tenant: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
