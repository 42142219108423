import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { UserStatus } from '../../types/userStatus';
import { Employment } from '../Employment/Employment';
import { transformCompanyMedicType, transformToBasicPerson } from '../Person/PersonTransformers';
import { transformV3ResourceToRole } from '../Role/RoleTransformers';
import { ShiftIndex } from '../Shift/Shift';
import {
    AuthenticatedUser,
    ShiftIndexUserResource,
    ShiftPlanningUser,
    TimerUser,
    User,
    UserProfileV3Resource,
    UserResource,
} from './User';

export const transformToUser = (
    user: UserResource,
    employment?: Employment,
): User => {
    const status = getEnumKeyByEnumValue(UserStatus, user.attributes.status);

    if (!status) {
        console.error(`[transformToUser]: Unknown status enum found named "${user.attributes.status}".`);
    }

    return {
        id: user.id,
        employeeNumber: user.attributes.employeeNumber,
        phone: user.attributes.phone,
        email: user.attributes.email,
        status: status || UserStatus.active,
        shouldTrackTime: user.attributes.shouldTrackTime,
        isPaidBasedOnTrackedTime: user.attributes.isPaidBasedOnTrackedTime,
        isEligibleForTimeForTime: user.attributes.isEligibleForTimeForTime,
        contractHours: user.attributes.contractHours,
        isRegistrationComplete: user.attributes.registrationComplete,
        isLoketAuthorized: user.attributes.isLoketAuthorized,
        loketEmployeeId: user.attributes.loketEmployeeId || undefined,
        employment,
        person: transformToBasicPerson(user.attributes.firstname, user.attributes.lastname, user.attributes.affix),
    };
};

export const transformToAuthenticatedUser = (user: UserProfileV3Resource): AuthenticatedUser => {
    const status = getEnumKeyByEnumValue(UserStatus, user.status);

    if (!status) {
        console.error(`[transformToUser]: Unknown status enum found named "${user.status}".`);
    }

    return {
        id: user.id,
        employeeNumber: user.employeeNumber,
        phone: user.phone,
        email: user.email,
        status: user.status || UserStatus.active,
        shouldTrackTime: user.shouldTrackTime,
        isPaidBasedOnTrackedTime: user.isPaidBasedOnTrackedTime,
        isEligibleForTimeForTime: user.isEligibleForTimeForTime,
        contractHours: user.contractHours,
        isRegistrationComplete: user.registrationComplete,
        isLoketAuthorized: user.isLoketAuthorized,
        loketEmployeeId: user.loketEmployeeId || undefined,
        person: transformToBasicPerson(user.firstname, user.lastname, user.affix || undefined),
        roles: user.roles.map(transformV3ResourceToRole),
    };
};

export const transformToShiftPlanningUser = (user: ShiftIndexUserResource): ShiftPlanningUser => ({
    id: user.id,
    employeeNumber: parseInt(user.attributes.employeeNumber, 10),
    companyMedic: transformCompanyMedicType(user.attributes.isCompanyMedic),
    firstName: user.attributes.firstname,
    shouldTrackTime: user.attributes.shouldTrackTime,
});

export const transformToTimerUser = (user: UserResource): TimerUser => ({
    id: user.id,
    employeeNumber: user.attributes.employeeNumber,
    firstName: user.attributes.firstname,
});

export const transformShiftPlanningUserToTimerUser = (shiftPlanningUser: ShiftPlanningUser): TimerUser => ({
    id: shiftPlanningUser.id,
    employeeNumber: shiftPlanningUser.employeeNumber,
    firstName: shiftPlanningUser.firstName,
});

export const transformShiftsToTimerUsers = (shifts: ShiftIndex[]): TimerUser[] => shifts
    .map(shift => shift.shiftPlannings
        .filter(shiftPlanning => shiftPlanning.planned && shiftPlanning.user.employeeNumber)
        .map(shiftPlanning => transformShiftPlanningUserToTimerUser(shiftPlanning.user)))
    .flat();

export const transformAuthenticatedUserToTimerUser = (user: AuthenticatedUser): TimerUser => ({
    id: user.id,
    employeeNumber: user.employeeNumber,
    firstName: user.person.firstname,
});
