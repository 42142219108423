import { FC, ReactElement } from 'react';

import './LoadingScreen.scss';

interface LoadingScreenProps {
    className?: string;
}

const LoadingScreen: FC<LoadingScreenProps> = ({ className = '' }): ReactElement => (
    <div className={`loading-screen ${className}`}>
        <div className="loading-screen__loader" />
    </div>
);

export default LoadingScreen;
