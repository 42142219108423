/* eslint-disable react/no-unknown-property */

import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconChevronRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 14 14">
        <path
            d="M5.52,7l5.3,5.74L9.66,14L3.18,7l6.48-7l1.16,1.26l-4.5,4.86L5.52,7z"
            transform="scale(-1, 1)"
            transform-origin="center"
        />
    </svg>
);

export default IconChevronRight;

/* eslint-enable react/no-unknown-property */
