export enum DateFormat {
    apiDateTime = 'yyyy-MM-dd\'T\'HH:mm:ss',
    apiParamsDateTime = 'yyyy-MM-dd HH:mm:ss',
    date = 'dd-MM-yyyy',
    dateLabel = 'EEEE dd-MM-yyyy',
    dateLabelShort = 'EEEEEE dd MMM',
    dateYearLabelShort = 'EEEEEE dd-MM-yyyy',
    dateTimeLabelShort = 'EEEEEE dd MMM, H:mm:ss',
    dateWithHoursMinutes = 'dd-MM-yyyy HH:mm',
    hoursMinutes = 'HH:mm',
    timeLabel = 'EEEE dd-MM-yyyy HH:mm',
    timeLabelShort = 'EEEEEE dd-MM-yyyy HH:mm',
}
