import { FC } from 'react';

import trans from '../../../helpers/trans';
import { IconButton } from '../../index';

import './LogOutButton.scss';

interface LogOutButtonProps {
    onClick: () => void;
    className?: string;
}

const LogOutButton: FC<LogOutButtonProps> = ({ onClick, className = '' }) => (
    <IconButton
        icon="log-out"
        iconPos="right"
        text={trans('common.logout')}
        onClick={onClick}
        className={`log-out-button ${className}`}
        iconClassName="log-out-button__icon"
    />
);

export default LogOutButton;
