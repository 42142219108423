import { FC, ReactElement } from 'react';

import './ValkLogo.scss';

interface ValkLogoProps {
    className?: string;
}

const ValkLogo: FC<ValkLogoProps> = ({ className = '' }): ReactElement => {
    const now = new Date();
    const isChristmas = now.getMonth() + 1 === 12 && now.getDate() > 7;
    const src = isChristmas ? '/images/valk-kerst-logo.svg' : '/images/valk-logo.svg';

    return (
        <div className={`valk-logo ${className}`}>
            <img
                alt="valk-logo"
                src={src}
                className="valk-logo__img"
            />
        </div>
    );
};

export default ValkLogo;
