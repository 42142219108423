import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';
import { AuthenticatedUser } from '../../entities/User/User';
import { Error } from '../../japi/types/Error';

export type UserState = AsyncReduxState<{
    isUserNotFound: boolean;
    authenticatedUser?: AuthenticatedUser;
    employeeNumber?: number;
    pin?: string;
}>;

const initialState: UserState = {
    ...initialAsyncReduxState,
    isUserNotFound: false,
};

export const UserReducerSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setAuthenticatedUser(state, action: PayloadAction<AuthenticatedUser | undefined>): UserState {
            return {
                ...state,
                authenticatedUser: action.payload,
            };
        },
        setUser(state, action: PayloadAction<{ employeeNumber: number, pin: string }>): UserState {
            return {
                ...state,
                employeeNumber: action.payload.employeeNumber,
                pin: action.payload.pin,
            };
        },
        logout(): UserState {
            return {
                ...initialState,
            };
        },
        setIsUserNotFound(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isUserNotFound: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error>): UserState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setAuthenticatedUser,
    setIsUserNotFound,
    setError,
    setUser,
    logout,
} = UserReducerSlice.actions;

export default UserReducerSlice.reducer;
