import { ShiftIndex } from '../../../../entities/Shift/Shift';
import { ShiftPlanning } from '../../../../entities/ShiftPlanning/ShiftPlanning';
import { addHours, addSeconds, areIntervalsOverlapping } from '../../../../helpers/date';

export const filterShiftWithinHourMargin = (
    shift: ShiftIndex | ShiftPlanning,
    hoursBefore: number,
    hoursAfter: number,
    now: Date,
): boolean => areIntervalsOverlapping({
    start: addHours(shift.period.start, -hoursBefore),
    end: addHours(shift.period.start, hoursAfter),
}, {
    start: now,
    end: addSeconds(now, 1),
});

export const hasShiftPlanningWithin4BeforeAnd2After = (shiftPlannings: ShiftPlanning[], now: Date, userId?: string): boolean => shiftPlannings
    .filter(shiftPlanning => shiftPlanning.user.id === userId)
    .some((shiftPlanning) => filterShiftWithinHourMargin(
        shiftPlanning,
        4,
        2,
        now,
    ));

export const hasShiftPlanningWithin1BeforeAnd1After = (shiftPlannings: ShiftPlanning[], now: Date, userId?: string): boolean => shiftPlannings
    .filter(shiftPlanning => shiftPlanning.user.id === userId)
    .some((shiftPlanning) => filterShiftWithinHourMargin(
        shiftPlanning,
        1,
        1,
        now,
    ));
