import { FC, ReactElement } from 'react';

import { LogOutButton } from '../../../compositions';
import trans from '../../../helpers/trans';

import './InactiveScreen.scss';

interface InactiveScreenProps {
    onLogOutButtonClick: () => void;
    className?: string;
}

const InactiveScreen: FC<InactiveScreenProps> = ({ onLogOutButtonClick, className = '' }): ReactElement => (
    <div className={`inactive-screen ${className}`}>
        <div className="inactive-screen__window">
            <h2 className="inactive-screen__title">
                {trans('containers.inactiveScreen.title')}
            </h2>

            <p className="inactive-screen__intro">
                {trans('containers.inactiveScreen.intro')}
            </p>

            <p className="inactive-screen__contact">
                {trans('containers.inactiveScreen.contact')}
            </p>
        </div>

        <LogOutButton
            onClick={onLogOutButtonClick}
            className="inactive-screen__log-out-button"
        />
    </div>
);

export default InactiveScreen;
