import {
    addDays as dateFnsAddDays,
    addHours as dateFnsAddHours,
    addMinutes as dateFnsAddMinutes,
    addMonths as dateFnsAddMonths,
    addSeconds as dateFnsAddSeconds,
    addYears as dateFnsAddYears,
    areIntervalsOverlapping as dateFnsAreIntervalsOverlapping,
    compareAsc as dateFnsCompareAsc,
    compareDesc as dateFnsCompareDesc,
    differenceInCalendarDays as dateFnsDifferenceInCalendarDays,
    differenceInDays as dateFnsDifferenceInDays,
    differenceInHours as dateFnsDifferenceInHours,
    differenceInMinutes as dateFnsDifferenceInMinutes,
    differenceInSeconds as dateFnsDifferenceInSeconds,
    eachDayOfInterval as dateFnsEachDayOfInterval,
    endOfDay as dateFnsEndOfDay,
    endOfMonth as dateFnsEndOfMonth,
    endOfWeek as dateFnsEndOfWeek,
    endOfYear as dateFnsEndOfYear,
    format as dateFnsFormat,
    getHours as dateFnsGetHours,
    getTime as dateFnsGetTime,
    isBefore as dateFnsIsBefore,
    isEqual as dateFnsIsEqual,
    isSameDay as dateFnsIsSameDay,
    isValid as dateFnsIsValid,
    max as dateFnsMax,
    parse as dateFnsParse,
    parseISO,
    setDay as dateFnsSetDay,
    setHours as dateFnsSetHours,
    setMinutes as dateFnsSetMinutes,
    setMonth as dateFnsSetMonth,
    setSeconds as dateFnsSetSeconds,
    setWeek as dateFnsSetWeek,
    setYear as dateFnsSetYear,
    startOfDay as dateFnsStartOfDay,
    startOfMonth as dateFnsStartOfMonth,
    startOfWeek as dateFnsStartOfWeek,
    startOfYear as dateFnsStartOfYear,
} from 'date-fns';
import nl from 'date-fns/locale/nl';
import { utcToZonedTime } from 'date-fns-tz';

import { Period } from '../entities/Period/Period';
import { Weekday, WeekdayResource } from '../types';

export const addDays = (date: number | Date, amount: number): Date => dateFnsAddDays(date, amount);
export const addHours = (date: number | Date, amount: number): Date => dateFnsAddHours(date, amount);
export const addMinutes = (date: number | Date, amount: number): Date => dateFnsAddMinutes(date, amount);
export const addMonths = (date: number | Date, amount: number): Date => dateFnsAddMonths(date, amount);
export const addSeconds = (date: number | Date, amount: number): Date => dateFnsAddSeconds(date, amount);
export const addYears = (date: number | Date, amount: number): Date => dateFnsAddYears(date, amount);
export const areIntervalsOverlapping = (intervalLeft: Interval, intervalRight: Interval, options?: { inclusive?: boolean }): boolean => {
    try {
        return dateFnsAreIntervalsOverlapping(intervalLeft, intervalRight, options);
    } catch {
        console.error('[areIntervalsOverlapping]', intervalLeft, intervalRight);
        return false;
    }
};
export const compareAsc = (dateLeft: number | Date, dateRight: number | Date): number => dateFnsCompareAsc(dateLeft, dateRight);
export const compareDesc = (dateLeft: number | Date, dateRight: number | Date): number => dateFnsCompareDesc(dateLeft, dateRight);
export const differenceInCalendarDays = (dateLeft: Date | number, dateRight: Date | number): number => dateFnsDifferenceInCalendarDays(dateLeft, dateRight);
export const differenceInDays = (dateLeft: Date | number, dateRight: Date | number): number => dateFnsDifferenceInDays(dateLeft, dateRight);
export const differenceInHours = (dateLeft: Date | number, dateRight: Date | number): number => dateFnsDifferenceInHours(dateLeft, dateRight);
export const differenceInMinutes = (dateLeft: Date | number, dateRight: Date | number): number => dateFnsDifferenceInMinutes(dateLeft, dateRight);
export const differenceInSeconds = (dateLeft: Date | number, dateRight: Date | number): number => dateFnsDifferenceInSeconds(dateLeft, dateRight);
export const eachDayOfInterval = (interval: Interval, options?: { step?: number }): Date[] => dateFnsEachDayOfInterval(interval, options);
export const endOfMonth = (date: Date): Date => dateFnsEndOfMonth(date);
export const endOfYear = (date: Date): Date => dateFnsEndOfYear(date);
export const endOfWeek = (date: Date, options?: { locale?: Locale, weekStartsOn?: Weekday}): Date => dateFnsEndOfWeek(date, options);
export const endOfDay = (date: Date): Date => dateFnsEndOfDay(date);
export const formatDate = (date: Date, newFormat = 'dd-MM-yyyy'): string => `${dateFnsFormat(date, newFormat, { locale: nl })}`;
export const getHours = (date: Date): number => dateFnsGetHours(date);
export const getTime = (date: Date): number => dateFnsGetTime(date);
export const isEqualOrWithin24Hours = (date1: Date, date2: Date): boolean => Math.abs(dateFnsDifferenceInHours(date1, date2)) <= 24;
export const isBefore = (date: Date | number, dateToCompare: Date | number): boolean => dateFnsIsBefore(date, dateToCompare);
export const isEqual = (date: Date, dateToCompare: Date): boolean => dateFnsIsEqual(date, dateToCompare);
export const isValid = (date: any): boolean => dateFnsIsValid(date);
export const isSameDay = (date: Date, dateToCompare: Date): boolean => dateFnsIsSameDay(date, dateToCompare);
export const max = (datesArray: (Date | number)[]): Date => dateFnsMax(datesArray);
export const parse = (
    dateString: string,
    formatString: string,
    referenceDate: Date | number,
    options?: {
        locale?: Locale,
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
        firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7,
        useAdditionalWeekYearTokens?: boolean,
        useAdditionalDayOfYearTokens?: boolean,
    },
): Date => dateFnsParse(dateString, formatString, referenceDate, options);
export const parseIso = (isoDateString: string): Date => utcToZonedTime(parseISO(isoDateString), 'Europe/Amsterdam');
export const setDay = (
    date: number | Date,
    day: number,
    options?: { locale?: Locale, weekStartsOn?: Weekday},
): Date => dateFnsSetDay(date, day, options);
export const setHours = (date: number | Date, amount: number): Date => dateFnsSetHours(date, amount);
export const setMinutes = (date: number | Date, amount: number): Date => dateFnsSetMinutes(date, amount);
export const setMonth = (date: number | Date, amount: number): Date => dateFnsSetMonth(date, amount);
export const setSeconds = (date: number | Date, amount: number): Date => dateFnsSetSeconds(date, amount);
export const setWeek = (
    date: number | Date,
    week: number,
    options?: { locale?: Locale, weekStartsOn?: Weekday, firstWeekContainsDate?: WeekdayResource},
): Date => dateFnsSetWeek(date, week, options);
export const setYear = (date: Date | number, year: number) => dateFnsSetYear(date, year);
export const startOfMonth = (date: Date): Date => dateFnsStartOfMonth(date);
export const startOfYear = (date: Date): Date => dateFnsStartOfYear(date);
export const startOfWeek = (date: Date, options?: { locale?: Locale, weekStartsOn?: Weekday}): Date => dateFnsStartOfWeek(date, options);
export const startOfDay = (date: Date): Date => dateFnsStartOfDay(date);

export const getDaysOfWeek = (date: Date): Date[] => {
    const from = startOfWeek(date, { weekStartsOn: 1 });
    const to = endOfWeek(date, { weekStartsOn: 1 });

    return eachDayOfInterval({
        start: from,
        end: to,
    });
};

export const getDaysOfMonth = (date: Date): Date[] => {
    const start = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
    const end = endOfMonth(date);

    return eachDayOfInterval({
        start,
        end,
    });
};

export const getDaysOfYears = (date: Date): Date[] => {
    const from = startOfYear(date);
    const to = endOfYear(date);

    return eachDayOfInterval({
        start: from,
        end: to,
    });
};

export const getDaysOfPeriod = (period: Period): Date[] => eachDayOfInterval({
    start: period.start,
    end: period.end,
});

export const convertDateStringToDDMMYYYY = (date: string): string => {
    const splitDate = date.split('-');

    return splitDate[0].length >= 4
        ? splitDate.reverse().join('-')
        : splitDate.join('-');
};

// The start and end of a day is from 04:00 until 04:00.
export const startOfDayInHours = 4;
export const startOfDayInHoursString = '4';
export const endOfDayInString = '03-59-59';

// Get period minus the startOfDayInHours. So instead of 04:00 until 04:00 you get 00:00 until 00:00
export const getPeriodWithoutStartOfDayCorrection = (period: Period): Period => ({
    start: addHours(period.start, -startOfDayInHours),
    end: addHours(period.end, -startOfDayInHours),
});

// Returns the start of workday of date (04:00)
export const getStartOfWorkDay = (date: Date): Date => parse(startOfDayInHoursString, 'HH', startOfDay(date));

// Returns the end of workday of date (03:59)
export const getEndOfWorkDay = (date: Date): Date => parse(endOfDayInString, 'HH-mm-ss', addDays(date, 1));

export const isDateStringValid = (dateString: string): boolean => {
    try {
        return isValid(new Date(dateString));
    } catch (e) {
        return false;
    }
};
