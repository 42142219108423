import { MealType, SettingKey } from '../../types';
import { Setting, TimerPauseDurationOption } from './Setting';
import { transformStringToTimerPauseDurationOption } from './SettingTransformers';

export const getSetting = (settingKey: SettingKey, settings: Setting[], departmentId?: string): Setting | undefined => {
    const generalSetting = settings
        .find(setting => setting.key === settingKey && setting.departmentId === undefined);
    const departmentSetting = settings
        .find(setting => setting.key === settingKey && setting.departmentId === departmentId);

    return departmentSetting || generalSetting;
};

export const getSettingEnabled = (settingKey: SettingKey, settings: Setting[], departmentId?: string): boolean => (
    getSetting(settingKey, settings, departmentId)?.isEnabled || false
);

export const filterTimerPauseDurationOptionBasedOnBillableTime = (option: TimerPauseDurationOption, billableTimeInHours: number): boolean => {
    if (billableTimeInHours >= 10) {
        return option.value > 30;
    }

    if (billableTimeInHours >= 5.5) {
        return option.value > 15;
    }

    return true;
};


export const getTimerPauseDurationOptions = (settings: Setting[], billableTimeInHours: number, departmentId?: string): TimerPauseDurationOption[] => {
    const setting = getSetting('TIMER_PAUSE_DURATION_OPTION', settings, departmentId);

    if (!setting) {
        return [];
    }

    const caoOptionEnabled = !!getSetting('ENABLE_CAO_MESSAGE_TIMER_OPTIONS', settings, departmentId)?.isEnabled;
    const numberStrings = setting.value.split(',');

    return numberStrings
        .map(transformStringToTimerPauseDurationOption)
        .filter(option => option.isActive)
        .filter(option => !caoOptionEnabled || filterTimerPauseDurationOptionBasedOnBillableTime(option, billableTimeInHours))
        .sort((a, b) => a.value - b.value);
};

export const getTimerMealOptions = (settings: Setting[], departmentId?: string): MealType[] => {
    const breadSetting = getSetting('MEAL_BREAD_ENABLED', settings, departmentId);
    const hotSetting = getSetting('MEAL_HOT_ENABLED', settings, departmentId);
    const noneSetting = getSetting('MEAL_NONE_ENABLED', settings, departmentId);

    return [
        ...(noneSetting?.isActive ? [MealType.none] : []),
        ...(breadSetting?.isActive ? [MealType.bread] : []),
        ...(hotSetting?.isActive ? [MealType.hot] : []),
    ];
};
