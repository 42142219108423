import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconBackspace: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 32 32" version="1.1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <g transform="translate(0 3)">
            <path className="stroke" d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" />
            <line x1="18" y1="9" x2="12" y2="15" />
            <line x1="12" y1="9" x2="18" y2="15" />
        </g>
    </svg>
);

export default IconBackspace;
