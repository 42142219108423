/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconNeutral: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 60 60" version="1.1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-482.000000, -354.000000)">
                <g transform="translate(302.000000, 334.000000)">
                    <g transform="translate(160.000000, 0.000000)">
                        <g transform="translate(20.000000, 20.000000)">
                            <path d="M30,0 C13.4582609,0 0,13.4582609 0,30 C0,46.5417391 13.4582609,60 30,60 C46.5417391,60 60,46.5417391 60,30 C60,13.4582609 46.5417391,0 30,0 Z M30,57.3913043 C14.8956522,57.3913043 2.60869565,45.1043478 2.60869565,30 C2.60869565,14.8956522 14.8956522,2.60869565 30,2.60869565 C45.1043478,2.60869565 57.3913043,14.8956522 57.3913043,30 C57.3913043,45.1043478 45.1043478,57.3913043 30,57.3913043 Z" fillRule="nonzero" />
                            <path d="M19.5652174,28.6956522 C21.7226087,28.6956522 23.4782609,26.94 23.4782609,24.7826087 C23.4782609,22.6252174 21.7226087,20.8695652 19.5652174,20.8695652 C17.4078261,20.8695652 15.6521739,22.6252174 15.6521739,24.7826087 C15.6521739,26.94 17.4078261,28.6956522 19.5652174,28.6956522 Z" id="Shape" fillRule="nonzero" />
                            <path d="M40.4347826,20.8695652 C38.2773913,20.8695652 36.5217391,22.6252174 36.5217391,24.7826087 C36.5217391,26.94 38.2773913,28.6956522 40.4347826,28.6956522 C42.5921739,28.6956522 44.3478261,26.94 44.3478261,24.7826087 C44.3478261,22.6252174 42.5921739,20.8695652 40.4347826,20.8695652 Z" id="Shape" fillRule="nonzero" />
                            <path d="M43.0434783,36.5217391 L16.9565217,36.5217391 C16.2365217,36.5217391 15.6521739,37.106087 15.6521739,37.826087 C15.6521739,38.546087 16.2365217,39.1304348 16.9565217,39.1304348 L43.0434783,39.1304348 C43.7634783,39.1304348 44.3478261,38.546087 44.3478261,37.826087 C44.3478261,37.106087 43.7634783,36.5217391 43.0434783,36.5217391 Z" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default IconNeutral;
/* eslint-enable max-len */
