import {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    ClockOutScreen,
    CommentScreen,
    GoodbyeScreen,
    HappinessScreen,
    MealScreen,
    PauseScreen,
} from '../../containers';
import { CommentScreenType } from '../../containers/@screens/CommentScreen/CommentScreen';
import {
    getSettingEnabled,
    getTimerMealOptions,
    getTimerPauseDurationOptions,
} from '../../entities/Setting/SettingHelpers';
import { getTrackRecordTotalWorkedHours, isCheckOutRecord } from '../../entities/TrackRecord/TrackRecordHelpers';
import { localStorageApiTokenKey } from '../../helpers/authorizedFetch';
import useLocalStorage from '../../helpers/hooks/useLocalStorage';
import useTimerUser from '../../helpers/hooks/useTimerUser';
import { updateLastCheckOutRecord } from '../../redux/trackRecords/trackRecordsActions';
import { delayedLogout } from '../../redux/user/userActions';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { HappinessType, MealType } from '../../types';
import { useClockOutHandlers } from './hooks/useClockOutHandlers';
import useFilledTrackFields from './hooks/useFilledTrackFields';
import useIsClockedOut from './hooks/useIsClockedOut';
import useLastUserRecord from './hooks/useLastUserRecord';

interface ConnectedClockOutScreenProps {
    departmentId?: string;
    employeeNumber: number;
    shiftId?: string;
    startApiDateTime: string;
    trackId?: string;
    className?: string;
}

const ConnectedClockOut: FC<ConnectedClockOutScreenProps> = ({
    departmentId,
    employeeNumber,
    shiftId,
    startApiDateTime,
    trackId,
    className = '',
}) => {
    const dispatch = useTypedDispatch();
    const [, storeApiToken] = useLocalStorage<string | undefined>(localStorageApiTokenKey, undefined);
    const [hasSkippedTrackDeviationRemark, setHasSkippedTrackDeviationRemark] = useState(false);

    const { isOnline, serverApiDateTime, serverDate } = useTypedSelector(state => state.appReducer);
    const { settings } = useTypedSelector(state => state.settingsReducer);

    const trackUser = useTimerUser(employeeNumber);
    const { lastUserRecord, isLoading } = useLastUserRecord(employeeNumber);
    const isClockedOut = useIsClockedOut(employeeNumber, trackId);

    const totalWorkedHours = useMemo(() => (lastUserRecord ? getTrackRecordTotalWorkedHours(lastUserRecord) : 0), [lastUserRecord]);
    const pauseDurationOptions = useMemo(() => getTimerPauseDurationOptions(settings, totalWorkedHours, departmentId), [settings, totalWorkedHours, departmentId]);
    const mealOptions = useMemo(() => getTimerMealOptions(settings, departmentId), [settings, departmentId]);

    const shouldEnterPause = pauseDurationOptions.length > 1;
    const shouldEnterHappiness = useMemo(() => getSettingEnabled('TIMER_ENABLE_FEEDBACK', settings, departmentId), [settings, departmentId]);
    const shouldEnterMeal = mealOptions.length > 1;
    const shouldEnterStartedWithoutShiftComment = !shiftId;
    const shouldEnterTrackDeviationRemark = !!(!hasSkippedTrackDeviationRemark && lastUserRecord && shiftId && isCheckOutRecord(lastUserRecord) && lastUserRecord.isDivergent);

    const {
        hasFilledPause,
        hasFilledHappiness,
        hasFilledMeal,
        hasFilledStartedWithoutShiftComment,
        hasFilledTrackDeviationRemark,
    } = useFilledTrackFields(lastUserRecord);

    const {
        onClockOutButtonClick,
        onPauseDurationClick,
        onHappinessClick,
        onMealClick,
        onSubmitStartedWithoutShiftComment,
        onSubmitTrackDeviationRemark,
        onLogOutButtonClick,
    } = useClockOutHandlers({
        isOnline,
        employeeNumber,
        lastUserRecord,
        serverApiDateTime,
        shiftId,
        startApiDateTime,
        trackId,
    });

    const handleSkipTrackDeviationRemark = (): void => {
        setHasSkippedTrackDeviationRemark(true);
    };

    const handleBackButtonClick = (): void => {
        if (lastUserRecord && isCheckOutRecord(lastUserRecord)) {
            dispatch(updateLastCheckOutRecord({
                ...lastUserRecord,
                deviationRemark: undefined,
                unplannedRemark: undefined,
                ...(shouldEnterHappiness && { happiness: undefined }),
                ...(shouldEnterMeal && { meal: undefined }),
                ...(shouldEnterPause && { pause: undefined }),
            }));
        }
    };

    useEffect((): void => {
        if (isClockedOut && lastUserRecord && isCheckOutRecord(lastUserRecord)) {
            // Pause, happiness and meal screens can be skipped if there's only 1 option
            dispatch(updateLastCheckOutRecord({
                ...lastUserRecord,
                ...(!shouldEnterHappiness && { happiness: HappinessType.neutral }),
                ...(!shouldEnterMeal && { meal: mealOptions[0] || MealType.none }),
                ...(!shouldEnterPause && { pause: pauseDurationOptions[0]?.value || 0 }),
            }));
        }
    }, [isClockedOut]);

    useEffect((): void => {
        if (
            isClockedOut
            && (!shouldEnterPause || hasFilledPause)
            && (!shouldEnterHappiness || hasFilledHappiness)
            && (!shouldEnterMeal || hasFilledMeal)
            && (!shouldEnterStartedWithoutShiftComment || hasFilledStartedWithoutShiftComment)
            && (!shouldEnterTrackDeviationRemark || hasFilledTrackDeviationRemark)
        ) {
            dispatch(delayedLogout(storeApiToken));
        }
    }, [
        isClockedOut,
        hasFilledPause,
        hasFilledHappiness,
        hasFilledMeal,
        hasFilledStartedWithoutShiftComment,
        hasFilledTrackDeviationRemark,
        shouldEnterTrackDeviationRemark,
    ]);

    if (!isClockedOut) {
        return (
            <ClockOutScreen
                isLoading={isLoading}
                serverTime={serverDate}
                startTime={new Date(startApiDateTime)}
                firstName={trackUser?.firstName}
                onClockOutButtonClick={onClockOutButtonClick}
                onLogOutButtonClick={onLogOutButtonClick}
                className={className}
            />
        );
    }

    if (shouldEnterPause && !hasFilledPause) {
        return (
            <PauseScreen
                firstName={trackUser?.firstName}
                pauseDurationOptions={pauseDurationOptions}
                onPauseDurationOptionsClick={onPauseDurationClick}
            />
        );
    }

    if (shouldEnterMeal && !hasFilledMeal) {
        return (
            <MealScreen
                showBackButton={shouldEnterPause || shouldEnterHappiness}
                firstName={trackUser?.firstName}
                mealTypes={mealOptions}
                onBackButtonClick={handleBackButtonClick}
                onMealButtonClick={onMealClick}
            />
        );
    }

    if (shouldEnterStartedWithoutShiftComment && !hasFilledStartedWithoutShiftComment) {
        return (
            <CommentScreen
                type={CommentScreenType.startedWithoutShift}
                showBackButton={shouldEnterPause || shouldEnterHappiness || shouldEnterMeal}
                firstName={trackUser?.firstName}
                onBackButtonClick={handleBackButtonClick}
                onSubmit={onSubmitStartedWithoutShiftComment}
            />
        );
    }

    if (shouldEnterTrackDeviationRemark && !hasFilledTrackDeviationRemark) {
        return (
            <CommentScreen
                showSkipButton
                type={CommentScreenType.trackDivergence}
                showBackButton={shouldEnterPause || shouldEnterHappiness || shouldEnterMeal}
                firstName={trackUser?.firstName}
                onBackButtonClick={handleBackButtonClick}
                onSkipButtonClick={handleSkipTrackDeviationRemark}
                onSubmit={onSubmitTrackDeviationRemark}
            />
        );
    }

    if (shouldEnterHappiness && !hasFilledHappiness) {
        return (
            <HappinessScreen
                showBackButton={
                    shouldEnterPause
                    || shouldEnterHappiness
                    || shouldEnterMeal
                    || shouldEnterStartedWithoutShiftComment
                    || shouldEnterTrackDeviationRemark
                }
                firstName={trackUser?.firstName}
                onBackButtonClick={handleBackButtonClick}
                onHappinessButtonClick={onHappinessClick}
            />
        );
    }

    return (
        <GoodbyeScreen firstName={trackUser?.firstName} />
    );
};

export default ConnectedClockOut;
