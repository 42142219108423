import { FC, ReactElement } from 'react';

import { MasterLoginScreen } from '../../containers';
import { verifyMasterCode } from '../../redux/masterCode/masterCodeActions';
import { useTypedDispatch, useTypedSelector } from '../../store';

const ConnectedMasterLoginScreen: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isFailed, isLoading } = useTypedSelector(state => state.masterCodeReducer);

    const handleSubmitMasterCode = (masterCode: string): void => {
        dispatch(verifyMasterCode(masterCode));
    };

    return (
        <MasterLoginScreen
            isFailed={isFailed}
            isLoading={isLoading}
            onSubmitMasterCode={handleSubmitMasterCode}
        />
    );
};

export default ConnectedMasterLoginScreen;
