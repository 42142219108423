import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { NumpadDisplay } from '../../../components';
import { Dots, NumpadInput } from '../../../compositions';
import { NumpadFunctionType } from '../../../types';
import { getLoginScreenTitleText } from './helpers/getLoginScreenTitleText';

import './LoginScreen.scss';

interface LoginScreenProps {
    isUserNotFound: boolean;
    onSubmit: (employeeNumber: number, pin: string) => void;
    className?: string;
}

export enum LoginScreenStep {
    employeeNumber = 'employeeNumber',
    pincode = 'pincode',
}

const employeeNumberMaxLength = 12;
const pinMaxLength = 4;

const LoginScreen: FC<LoginScreenProps> = ({ isUserNotFound, onSubmit, className = '' }): ReactElement => {
    const [step, setStep] = useState(LoginScreenStep.employeeNumber);
    const [employeeNumber, setEmployeeNumber] = useState('');
    const [pin, setPin] = useState('');

    const handleNumpadButtonKeyUpForEmployeeNumber = (value: number | NumpadFunctionType): void => {
        if (value === NumpadFunctionType.submit) {
            setStep(LoginScreenStep.pincode);

            return;
        }

        if (value === NumpadFunctionType.backspace) {
            setEmployeeNumber(employeeNumber.substring(0, employeeNumber.length - 1));

            return;
        }

        if (employeeNumber.length === employeeNumberMaxLength) {
            return;
        }

        setEmployeeNumber(`${employeeNumber}${value}`);
    };

    const handleNumpadButtonKeyUpForPincode = (value: number | NumpadFunctionType): void => {
        if (value === NumpadFunctionType.backspace) {
            setPin(pin.substring(0, pin.length - 1));

            return;
        }

        setPin(`${pin}${value}`);
    };

    const handleNumpadButtonKeyUp = (value: number | NumpadFunctionType): void => {
        if (step === LoginScreenStep.employeeNumber) {
            handleNumpadButtonKeyUpForEmployeeNumber(value);

            return;
        }

        handleNumpadButtonKeyUpForPincode(value);
    };

    useEffect((): void => {
        if (pin.length === pinMaxLength) {
            const parsedEmployeeNumber = parseInt(employeeNumber, 10);
            const parsedPin = parseInt(pin, 10);

            if (Number.isNaN(parsedEmployeeNumber)) {
                console.error('EmployeeNumber is not a number');

                return;
            }

            if (Number.isNaN(parsedPin)) {
                console.error('Pin is not a number');

                return;
            }

            onSubmit(parsedEmployeeNumber, pin);
        }
    }, [pin]);

    useEffect((): void => {
        if (isUserNotFound) {
            setStep(LoginScreenStep.employeeNumber);
            setEmployeeNumber('');
            setPin('');
        }
    }, [isUserNotFound]);

    return (
        <div className={`login-screen ${className}`}>
            <h1 className="login-screen__title">
                {getLoginScreenTitleText(step, isUserNotFound)}
            </h1>

            <div className="login-screen__numpad">
                {step === LoginScreenStep.employeeNumber ? (
                    <NumpadDisplay
                        value={employeeNumber}
                        className="login-screen__numpad-display"
                    />
                ) : (
                    <Dots
                        amount={pinMaxLength}
                        filledAmount={pin.length}
                        className="login-screen__dots"
                    />
                )}

                <NumpadInput
                    showSubmitButton={step === LoginScreenStep.employeeNumber && !!employeeNumber}
                    onButtonKeyUp={handleNumpadButtonKeyUp}
                    className="login-screen__numpad-input"
                />
            </div>
        </div>
    );
};

export default LoginScreen;
