import React from 'react';

import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { configureStore } from './store';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson = require('../package.json');

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://022b29acd7064c9fbc7de1b3200522dd@o62714.ingest.sentry.io/5226635',
        release: `${packageJson.name}@${packageJson.version}`,
        environment: process.env.REACT_APP_APP_ENV,
    });
}

if (process.env.REACT_APP_APP_ENV !== 'local' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
}

export const appRootId = 'app-root';
const container = document.getElementById(appRootId) as Element;
const root = createRoot(container);

configureStore({}).then(store => {
    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>,
    );
});
