import trans from '../../../../helpers/trans';
import { LoginScreenStep } from '../LoginScreen';

export const getLoginScreenTitleText = (step: LoginScreenStep, isUserNotFound: boolean): string => {
    if (step === LoginScreenStep.pincode) {
        return trans('containers.loginScreen.enterPincode');
    }

    if (isUserNotFound) {
        return trans('containers.loginScreen.userNotFound');
    }

    return trans('containers.loginScreen.title');
};
