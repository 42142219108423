import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';
import { CheckInRecord, CheckOutRecord } from '../../entities/TrackRecord/TrackRecord';
import { transformTrackRecordsDomStringToTrackRecords } from '../../entities/TrackRecord/TrackRecordTransformers';
import { Error } from '../../japi/types/Error';

const localStorageTracksKey = 'trackRecords';
const localStorageTracksDomString = localStorage.getItem(localStorageTracksKey);
const localStorageTracks = localStorageTracksDomString ? transformTrackRecordsDomStringToTrackRecords(localStorageTracksDomString) : [];

export type TrackRecordsState = AsyncReduxState<{
    isSendTrackRecordsSuccessful: boolean;
    trackRecords: (CheckInRecord | CheckOutRecord)[];
}>;

const initialState: TrackRecordsState = {
    ...initialAsyncReduxState,
    isSendTrackRecordsSuccessful: false,
    trackRecords: localStorageTracks,
};

export const TrackRecordsSlice = createSlice({
    name: 'trackRecordsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): TrackRecordsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSendTrackRecordsSuccessful(state, action: PayloadAction<boolean>): TrackRecordsState {
            return {
                ...state,
                isSendTrackRecordsSuccessful: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): TrackRecordsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setTrackRecords(state, action: PayloadAction<(CheckInRecord | CheckOutRecord)[]>): TrackRecordsState {
            localStorage.setItem(localStorageTracksKey, JSON.stringify(action.payload));

            return {
                ...state,
                trackRecords: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): TrackRecordsState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSendTrackRecordsSuccessful,
    setIsSuccessful,
    setTrackRecords,
    setError,
} = TrackRecordsSlice.actions;

export default TrackRecordsSlice.reducer;
