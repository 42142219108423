import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { RoleType } from '../../types/roleType';
import { Role, RoleV3Resource } from './Role';

export const transformV3ResourceToRole = (resource: RoleV3Resource): Role => {
    const roleTypeKey = getEnumKeyByEnumValue(RoleType, resource.slug);

    if (!roleTypeKey) {
        console.error(`[transformV3ResourceToRole]: Unknown slug found named "${resource.slug}".`);
    }

    return {
        id: resource.id,
        name: resource.name,
        slug: roleTypeKey ? RoleType[roleTypeKey] : RoleType.employee,
        public: resource.public,
        assignable: resource.assignable,
        priority: resource.priority,
    };
};
