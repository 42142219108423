import { FC, useState } from 'react';

import { Button } from '../../../components';
import { BackButton, SkipButton } from '../../../compositions';
import trans from '../../../helpers/trans';

import './CommentScreen.scss';

export enum CommentScreenType {
    startedWithoutShift = 'startedWithoutShift',
    trackDivergence = 'trackDivergence',
}

interface CommentScreenProps {
    showBackButton?: boolean;
    showSkipButton?: boolean;
    firstName?: string;
    type: CommentScreenType;
    onBackButtonClick: () => void;
    onSkipButtonClick?: () => void;
    onSubmit: (comment: string) => void;
    className?: string;
}

const CommentScreen: FC<CommentScreenProps> = ({
    showBackButton = false,
    showSkipButton = false,
    firstName,
    type,
    onBackButtonClick,
    onSkipButtonClick,
    onSubmit,
    className = '',
}) => {
    const [comment, setComment] = useState('');

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value || '');
    };

    return (
        <div className={`comment-screen ${className}`}>
            <h1>{firstName}</h1>
            <h1 className="comment-screen__title">
                {type === CommentScreenType.startedWithoutShift ? (
                    trans('containers.commentScreen.startedWithoutShiftTitle')
                ) : (
                    trans('containers.commentScreen.trackDivergenceTitle')
                )}
            </h1>
            <textarea
                onChange={handleTextAreaChange}
                value={comment}
                className="comment-screen__textarea"
            />
            <div className="comment-screen__buttons-wrapper">
                <Button
                    disabled={!comment.length}
                    text={trans('common.submit')}
                    onClick={() => onSubmit(comment)}
                    className="comment-screen__submit-button"
                />
                {showSkipButton && (
                    <SkipButton
                        onClick={onSkipButtonClick}
                        className="comment-screen__skip-button"
                    />
                )}
            </div>
            {showBackButton && (
                <BackButton
                    onClick={onBackButtonClick}
                    className="comment-screen__back-button"
                />
            )}
        </div>
    );
};

export default CommentScreen;
