import { FC, ReactElement } from 'react';

import {
    IconBackspace,
    IconChevronLeft,
    IconChevronRight,
    IconLogOut,
    IconNeutral,
    IconSatisfied,
    IconUnsatisfied,
} from './set';

import './Icon.scss';

export interface SvgIconProps {
    className?: string;
}

export interface IconSet {
    'backspace': FC<SvgIconProps>;
    'chevron-left': FC<SvgIconProps>;
    'chevron-right': FC<SvgIconProps>;
    'log-out': FC<SvgIconProps>;
    'neutral': FC<SvgIconProps>;
    'satisfied': FC<SvgIconProps>;
    'unsatisfied': FC<SvgIconProps>;
}

const icons: IconSet = {
    backspace: IconBackspace,
    'chevron-left': IconChevronLeft,
    'chevron-right': IconChevronRight,
    'log-out': IconLogOut,
    neutral: IconNeutral,
    satisfied: IconSatisfied,
    unsatisfied: IconUnsatisfied,
};

export interface IconProps extends SvgIconProps {
    name: keyof IconSet;
}

const Icon: FC<IconProps> = ({ name, className = '' }): ReactElement | null => {
    const IconComponent = icons[name] ? icons[name] : null;

    return IconComponent
        ? <IconComponent className={className} />
        : null;
};

export default Icon;
