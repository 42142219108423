import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';
import { Setting } from '../../entities/Setting/Setting';
import { Error } from '../../japi/types/Error';

const localStorageSettingsKey = 'settings';
const localStorageSettingsDomString = localStorage.getItem(localStorageSettingsKey);
const localStorageSettings = localStorageSettingsDomString ? JSON.parse(localStorageSettingsDomString) : [];

export type SettingsState = AsyncReduxState<{
    settings: Setting[];
}>;

const initialState: SettingsState = {
    ...initialAsyncReduxState,
    settings: localStorageSettings,
};

export const SettingsSlice = createSlice({
    name: 'settingsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SettingsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setSettings(state, action: PayloadAction<Setting[]>): SettingsState {
            localStorage.setItem(localStorageSettingsKey, JSON.stringify(action.payload));

            return {
                ...state,
                settings: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): SettingsState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setSettings,
    setError,
} = SettingsSlice.actions;

export default SettingsSlice.reducer;
