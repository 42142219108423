import { useEffect, useState } from 'react';

import { useTypedSelector } from '../../store';

const useDebugLogging = (): void => {
    const [initialized, setInitialized] = useState(false);
    const { trackRecords } = useTypedSelector(state => state.trackRecordsReducer);
    const { tracks } = useTypedSelector(state => state.tracksReducer);

    useEffect((): void => {
        if (process.env.REACT_APP_APP_ENV === 'production') {
            return;
        }

        if (!initialized) {
            console.log('Clocked in tracks store parsed from localStorage of type ClockedInTrack[]:');
        }

        console.table(tracks);

        setInitialized(true);
    }, [tracks]);

    useEffect((): void => {
        if (process.env.REACT_APP_APP_ENV === 'production') {
            return;
        }

        if (!initialized) {
            console.log('Track records store parsed from localStorage of type (CheckInRecord | CheckOutRecord)[]:');
        }

        console.table(trackRecords);

        setInitialized(true);
    }, [trackRecords]);
};

export default useDebugLogging;
