import { useEffect, useState } from 'react';

import { pingApiCall } from '../../entities/Ping/PingService';
import { setIsOnline, setServerTime as setAppServerTime } from '../../redux/app/appReducer';
import { useTypedDispatch } from '../../store';
import { DateFormat } from '../../types';
import { formatDate } from '../date';
import { delay } from '../delay';

// The delay after a poll before calling the next poll
const pollingDelay = 3000;

// Threshold of a poll call to be considered a timeout
const pollingTimeoutThreshold = 5000;

// The amount of successive success / fail calls it takes to switch between offline / online
const pollingSwitchThreshold = 2;

const useOnlineCheck = (): void => {
    const dispatch = useTypedDispatch();

    const [isOnlineState, setIsOnlineState] = useState(navigator.onLine);
    const [isPollingSuccess, setIsPollingSuccess] = useState(false);
    const [isPollingFail, setIsPollingFail] = useState(false);

    const [pollingSuccesses, setPollingSuccesses] = useState(0);
    const [pollingFails, setPollingFails] = useState(0);

    const [serverTime, setServerTime] = useState<string>(formatDate(new Date(), DateFormat.apiDateTime));

    useEffect((): () => void => {
        let isApiSubscribed = true;

        const callPollingApiCall = async () => {
            const ping = await pingApiCall(pollingTimeoutThreshold);

            if (!isApiSubscribed) {
                return;
            }

            if (ping) {
                setIsPollingSuccess(true);
                setServerTime(ping.atom);
            } else {
                setIsPollingFail(true);
                setServerTime(formatDate(new Date(), DateFormat.apiDateTime));
            }

            await delay(pollingDelay);

            callPollingApiCall();
        };

        callPollingApiCall();

        return () => {
            isApiSubscribed = false;
        };
    }, []);

    useEffect((): void => {
        if (isPollingSuccess) {
            setPollingSuccesses(pollingSuccesses + 1);
            setPollingFails(0);
            setIsPollingSuccess(false);
        }
    }, [isPollingSuccess]);

    useEffect((): void => {
        if (isPollingFail) {
            setPollingFails(pollingFails + 1);
            setPollingSuccesses(0);
            setIsPollingFail(false);
        }
    }, [isPollingFail]);

    useEffect((): void => {
        if (pollingSuccesses >= pollingSwitchThreshold) {
            setIsOnlineState(true);
        }

        if (pollingFails >= pollingSwitchThreshold) {
            setIsOnlineState(false);
        }
    }, [pollingSuccesses, pollingFails]);

    useEffect((): void => {
        dispatch(setIsOnline(isOnlineState));
    }, [isOnlineState]);

    useEffect((): void => {
        dispatch(setAppServerTime(serverTime));
    }, [serverTime]);
};

export default useOnlineCheck;
