import * as Sentry from '@sentry/browser';

import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { CheckInRecord, CheckOutRecord } from '../../entities/TrackRecord/TrackRecord';
import { putTrackRecordsApiCall } from '../../entities/TrackRecord/TrackRecordService';
import { TypedDispatch } from '../../store';
import { setTrackRecords } from './trackRecordsReducer';

export const sendRecords = async (records: (CheckInRecord | CheckOutRecord)[]): Promise<boolean> => {
    if (process.env.REACT_APP_APP_ENV !== 'production') {
        console.log('%c Sending records!', 'background: #222; color: #bada55; font-size: 1.5rem;');
        console.log('The following records are sent to API:');
        console.table(records);
    }

    try {
        Sentry.captureMessage('trackRecordsHelper: sending tracks to API', {
            extra: {
                source: 'sendRecords',
                tenant: process.env.REACT_APP_TENANT || window.location.hostname,
                records,
            },
        });
    } catch (_e) {
        //
    }

    const response = await putTrackRecordsApiCall(records);

    if (!isFetchResultSuccessful(response)) {
        console.error('[sendRecordsHelper]', response);

        Sentry.captureMessage(`sendRecordsHelper failed with status code ${response.status}`, {
            extra: {
                source: 'sendRecordsHelper',
                tenant: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        return false;
    }

    return response.data;
};

export const sendRecordsAndClearStore = async (records: (CheckInRecord | CheckOutRecord)[], dispatch: TypedDispatch): Promise<boolean> => {
    const result = await sendRecords(records);

    if (result) {
        dispatch(setTrackRecords([]));
    }

    return result;
};
