import { SettingKey } from '../../types';
import { Setting, SettingResource, TimerPauseDurationOption } from './Setting';

export const transformSetting = (resource: SettingResource, departmentId?: string): Setting => ({
    id: resource.id,
    isActive: resource.attributes.active,
    isEnabled: resource.attributes.value === '1',
    departmentId,
    key: resource.attributes.key as SettingKey,
    value: resource.attributes.value,
});

export const transformStringToTimerPauseDurationOption = (value: string): TimerPauseDurationOption => {
    const [amount, active] = value.split(':');

    return {
        id: `id-${amount}`,
        isActive: active === '1',
        value: parseInt(amount, 10),
    };
};
