import { CheckInRecord, CheckOutRecord } from '../../../entities/TrackRecord/TrackRecord';
import { useTypedSelector } from '../../../store';

interface UseLastUserRecordResult {
    lastUserRecord?: CheckInRecord | CheckOutRecord;
    isLoading: boolean;
}

export const useLastUserRecord = (employeeNumber: number): UseLastUserRecordResult => {
    const { trackRecords, isLoading } = useTypedSelector(state => state.trackRecordsReducer);

    const userRecords = trackRecords.filter(trackRecord => trackRecord.employeeNumber === employeeNumber);
    const lastUserRecord = userRecords.length ? userRecords[userRecords.length - 1] : undefined;

    return { lastUserRecord, isLoading };
};

export default useLastUserRecord;
