import * as Sentry from '@sentry/browser';

import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { CheckInRecord, CheckOutRecord } from './TrackRecord';
import { TrackRecordResponse } from './TrackRecordHelpers';
import { transformTrackRecordsToApiCallBody } from './TrackRecordTransformers';

export const putTrackRecordsApiCall = async (
    records: (CheckInRecord | CheckOutRecord)[],
): Promise<FetchCollectionResult<boolean, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/api/v3/tracks/batch-insert',
        });

        const body = transformTrackRecordsToApiCallBody(records);

        const response = await authorizedFetch(url, {
            method: 'PUT',
            body: JSON.stringify(body),
        });

        const doc: TrackRecordResponse = await response.json();

        if (
            doc.status !== 200
            && doc.status !== 201
        ) {
            Sentry.captureMessage(`putTrackRecordsApiCall failed with HTTP status ${doc.status}`, {
                extra: {
                    source: 'putTrackRecordsApiCall',
                    tenant: process.env.REACT_APP_TENANT || window.location.hostname,
                },
            });

            return {
                status: doc.status,
                type: FetchResultType.Error,
                error: trans('errors.putTrackRecords'),
            };
        }

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[putTrackRecordsApiCall]', error);

        Sentry.captureException(error, {
            extra: {
                source: 'putTrackRecordsApiCall',
                tenant: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.putTrackRecords'),
        };
    }
};
