import { FC } from 'react';

import { BackButton, IconButton } from '../../../compositions';
import trans from '../../../helpers/trans';
import { HappinessType } from '../../../types';

import './HappinessScreen.scss';

interface HappinessScreenProps {
    showBackButton?: boolean;
    firstName?: string;
    onBackButtonClick: () => void;
    onHappinessButtonClick: (pause: HappinessType) => void;
    className?: string;
}

const HappinessScreen: FC<HappinessScreenProps> = ({
    showBackButton = false,
    firstName,
    onBackButtonClick,
    onHappinessButtonClick,
    className = '',
}) => (
    <div className={`happiness-screen ${className}`}>
        <h1>{firstName}</h1>
        <h1 className="happiness-screen__title">
            {trans('containers.happinessScreen.title')}
        </h1>
        <div className="happiness-screen__buttons-wrapper">
            <IconButton
                hideLabel
                text={HappinessType.unsatisfied}
                icon="unsatisfied"
                onClick={() => onHappinessButtonClick(HappinessType.unsatisfied)}
                className="happiness-screen__button"
                iconClassName="happiness-screen__button-icon happiness-screen__button-icon--is-unsatisfied"
            />
            <IconButton
                hideLabel
                text={HappinessType.neutral}
                icon="neutral"
                onClick={() => onHappinessButtonClick(HappinessType.neutral)}
                className="happiness-screen__button"
                iconClassName="happiness-screen__button-icon happiness-screen__button-icon--is-neutral"
            />
            <IconButton
                hideLabel
                text={HappinessType.satisfied}
                icon="satisfied"
                onClick={() => onHappinessButtonClick(HappinessType.satisfied)}
                className="happiness-screen__button"
                iconClassName="happiness-screen__button-icon happiness-screen__button-icon--is-satisfied"
            />
        </div>
        {showBackButton && (
            <BackButton
                onClick={onBackButtonClick}
                className="happiness-screen__back-button"
            />
        )}
    </div>
);

export default HappinessScreen;
