export enum RoleType {
    admin = 'admin',
    headManager = 'head-manager',
    humanResources = 'human-resources',
    juniorManager = 'junior-manager',
    employee = 'employee',
    establishmentManager = 'establishment-manager',
    manager = 'manager',
    viewer = 'viewer',
}
