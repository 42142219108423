import { Dispatch } from 'react';

import {
    authenticateEmployeeApiCall,
    getAuthenticatedUserApiCall,
} from '../../entities/Authentication/AuthenticationService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { AuthenticatedUser } from '../../entities/User/User';
import { transformAuthenticatedUserToTimerUser } from '../../entities/User/UserTransformers';
import { delay } from '../../helpers/delay';
import { ReducerGetter, TypedDispatch } from '../../store';
import { sendRecords, sendRecordsAndClearStore } from '../trackRecords/trackRecordsHelpers';
import { setTrackRecords } from '../trackRecords/trackRecordsReducer';
import { fetchClockedInTracks } from '../tracks/tracksActions';
import { setTracks } from '../tracks/tracksReducer';
import { getUpdatedUsers } from '../users/usersHelpers';
import {
    logout,
    setAuthenticatedUser,
    setIsLoading,
    setIsUserNotFound,
    setUser,
} from './userReducer';

export const delayedLogout = (storeApiToken: Dispatch<string | undefined>) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    const { appReducer, trackRecordsReducer } = getState();
    const { isOnline } = appReducer;
    const { trackRecords } = trackRecordsReducer;

    if (isOnline && trackRecords.length) {
        await sendRecordsAndClearStore(trackRecords, dispatch);

        storeApiToken(undefined);
        dispatch(logout());

        return;
    }

    await delay(2000);

    dispatch(logout());
};

export const offlineLogin = (employeeNumber: number, pin: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    const { usersReducer } = getState();
    const { users } = usersReducer;

    dispatch(setIsUserNotFound(false));
    dispatch(setIsLoading(true));

    await delay(500);

    const userExists = users.some(user => user.employeeNumber === employeeNumber);

    if (userExists) {
        dispatch(setUser({ employeeNumber, pin }));
    } else {
        dispatch(setIsUserNotFound(true));
    }

    dispatch(setIsLoading(false));
};

const getAuthenticatedUser = async (): Promise<AuthenticatedUser> => {
    const response = await getAuthenticatedUserApiCall();

    if (!isFetchResultSuccessful(response)) {
        throw new Error(`[getAuthenticatedUser]: ${response.error}`);
    }

    return response.data;
};

export const onlineLogin = (
    employeeNumber: number,
    pin: string,
    storeApiToken: Dispatch<string | undefined>,
) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    const { trackRecordsReducer, usersReducer } = getState();
    const { trackRecords } = trackRecordsReducer;
    const { users } = usersReducer;

    dispatch(setIsUserNotFound(false));
    dispatch(setIsLoading(true));

    try {
        const authResponse = await authenticateEmployeeApiCall(employeeNumber, pin);

        if (!isFetchResultSuccessful(authResponse)) {
            dispatch(setIsUserNotFound(true));

            return;
        }

        storeApiToken(authResponse.data.token);

        const [authenticatedUser] = await Promise.all([
            getAuthenticatedUser(),
            trackRecords.length && sendRecords(trackRecords),
        ]);

        const updatedUsers = getUpdatedUsers(users, [transformAuthenticatedUserToTimerUser(authenticatedUser)]);
        const clockedInTracks = await fetchClockedInTracks(updatedUsers);

        dispatch(setTrackRecords([]));
        dispatch(setTracks(clockedInTracks));
        dispatch(setUser({ employeeNumber, pin }));
        dispatch(setAuthenticatedUser(authenticatedUser));
    } catch (error) {
        console.error('[onlineLogin]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
