import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';
import { formatDate, parseIso } from '../../helpers/date';
import { transformAtomDateToApiDateTime } from '../../helpers/date/transformAtomDateToApiDate';
import { Locale } from '../../helpers/trans';
import { Error } from '../../japi/types/Error';
import { BeforeInstallPromptEvent, DateFormat } from '../../types';

export type AppState = AsyncReduxState<{
    darkModeIsActive: boolean;
    isOnline: boolean;
    pwaIsInstalled: boolean;
    pwaInstallEvent?: BeforeInstallPromptEvent;
    currentVersion: string;
    latestVersion: string;
    serverApiDateTime: string;
    serverDate: Date;
    locale: Locale;
}>;

const initialState: AppState = {
    ...initialAsyncReduxState,
    isOnline: true,
    darkModeIsActive: false,
    pwaIsInstalled: false,
    currentVersion: '',
    latestVersion: '',
    serverApiDateTime: formatDate(new Date(), DateFormat.apiDateTime),
    serverDate: new Date(),
    locale: Locale.nl,
};

const appSlice = createSlice({
    name: 'appReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsOnline(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                isOnline: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): AppState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDarkModeIsActive(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                darkModeIsActive: action.payload,
            };
        },
        setPwaIsInstalled(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                pwaIsInstalled: action.payload,
            };
        },
        setPwaInstallEvent(state, action: PayloadAction<BeforeInstallPromptEvent | undefined>): AppState {
            return {
                ...state,
                pwaInstallEvent: action.payload,
            };
        },
        setCurrentVersion(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                currentVersion: action.payload,
            };
        },
        setLatestVersion(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                latestVersion: action.payload,
            };
        },
        setLocale(state, action: PayloadAction<Locale>): AppState {
            return {
                ...state,
                locale: action.payload,
            };
        },
        setServerTime(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                serverApiDateTime: transformAtomDateToApiDateTime(action.payload),
                serverDate: parseIso(action.payload),
            };
        },
    },
});

export const {
    setIsLoading,
    setIsOnline,
    setError,
    setDarkModeIsActive,
    setPwaIsInstalled,
    setPwaInstallEvent,
    setCurrentVersion,
    setLatestVersion,
    setLocale,
    setServerTime,
} = appSlice.actions;

export default appSlice.reducer;
