import { ReactElement, useEffect } from 'react';

import { ConnectedMasterLoginScreen, ConnectedTimer } from './connectors';
import { localStorageApiTokenKey } from './helpers/authorizedFetch';
import useDebugLogging from './helpers/hooks/useDebugLogging';
import useLocalStorage from './helpers/hooks/useLocalStorage';
import useOnlinePolling from './helpers/hooks/useOnlinePolling';
import { fetchSettings } from './redux/settings/settingsActions';
import { fetchShifts } from './redux/shifts/shiftsActions';
import { fetchTimerUsers } from './redux/users/usersActions';
import { useTypedDispatch, useTypedSelector } from './store';
import { RoleType } from './types/roleType';

import './styles/global.scss';

window.localStorage.removeItem(localStorageApiTokenKey);

const App = (): ReactElement => {
    const dispatch = useTypedDispatch();

    useOnlinePolling();
    useDebugLogging();

    const { isOnline } = useTypedSelector(state => state.appReducer);
    const { isAuthenticated: isMasterCodeAuthenticated } = useTypedSelector(state => state.masterCodeReducer);
    const { authenticatedUser } = useTypedSelector(state => state.userReducer);

    const [apiToken] = useLocalStorage<string | undefined>(localStorageApiTokenKey, undefined);

    const isAuthenticated = !!apiToken;

    useEffect((): void => {
        if (!isAuthenticated) {
            return;
        }

        dispatch(fetchShifts());
        dispatch(fetchSettings());
    }, [isAuthenticated]);

    useEffect((): void => {
        if (!isOnline || !authenticatedUser) {
            return;
        }

        // The Establishment Manager is able to retrieve users directly from the user's endpoint,
        // rather than having to derive them from the shifts.
        if (authenticatedUser.roles.some(role => role.slug === RoleType.establishmentManager)) {
            dispatch(fetchTimerUsers());
        }
    }, [authenticatedUser]);

    if (!isMasterCodeAuthenticated) {
        return <ConnectedMasterLoginScreen />;
    }

    return <ConnectedTimer />;
};

export default App;
