import { ShiftIndex } from '../../entities/Shift/Shift';
import { ShiftsPerEmployee } from './shiftsReducer';

export const reduceShiftsToShiftsPerEmployeeObject = (shifts: ShiftIndex[]): ShiftsPerEmployee => {
    const employeeNumbers: number[] = shifts
        .map(shift => shift.shiftPlannings
            .filter(shiftPlanning => shiftPlanning.planned && shiftPlanning.user.employeeNumber)
            .map(shiftPlanning => shiftPlanning.user.employeeNumber))
        .flat();

    return employeeNumbers.reduce((total, employeeNumber) => {
        if (total[employeeNumber]) {
            return total;
        }

        const userShifts = shifts
            .filter(shift => shift.shiftPlannings
                .some(shiftPlanning => shiftPlanning.user.employeeNumber === employeeNumber));

        return {
            ...total,
            [employeeNumber]: userShifts,
        };
    }, {} as ShiftsPerEmployee);
};
