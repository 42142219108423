import { useEffect } from 'react';

const useInterval = (callback: () => void, delay = 1000, deps: any[] = []): void => {
    useEffect((): () => void => {
        const tick = (): void => callback();

        const interval = setInterval(tick, delay);

        return (): void => clearInterval(interval);
    }, deps);
};

export default useInterval;
