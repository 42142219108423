import { FC, ReactElement } from 'react';

import classnames from 'classnames';

import './Dot.scss';

interface DotProps {
    isFilled: boolean;
    className?: string;
}

const Dot: FC<DotProps> = ({ isFilled, className = '' }): ReactElement => {
    const dotClassName = classnames('dot', {
        'dot--is-filled': isFilled,
    }, className);

    return (
        <div className={dotClassName} />
    );
};

export default Dot;
