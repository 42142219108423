import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { transformShiftsDomStringToShifts } from '../../entities/Shift/ShiftTransformers';
import { getUniqueArrayList } from '../../helpers/array';
import { differenceInHours } from '../../helpers/date';
import { Error } from '../../japi/types/Error';
import { reduceShiftsToShiftsPerEmployeeObject } from './shiftsHelpers';

const localStorageShiftsKey = 'shifts';
const localStorageShiftsDomString = localStorage.getItem(localStorageShiftsKey);
const localStorageShifts = localStorageShiftsDomString ? transformShiftsDomStringToShifts(localStorageShiftsDomString) : [];
const localStorageShiftsPerEmployee = reduceShiftsToShiftsPerEmployeeObject(localStorageShifts);

export type ShiftsPerEmployee = { [employeeNumber: string]: ShiftIndex[] };

export type ShiftsState = AsyncReduxState<{
    shifts: ShiftIndex[];
    shiftsPerEmployee: ShiftsPerEmployee;
}>;

const initialState: ShiftsState = {
    ...initialAsyncReduxState,
    shifts: localStorageShifts,
    shiftsPerEmployee: localStorageShiftsPerEmployee,
};

export const ShiftsSlice = createSlice({
    name: 'shiftsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ShiftsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setUserShifts(state, action: PayloadAction<ShiftIndex[]>): ShiftsState {
            const storeShifts = [...state.shifts]
                .filter(shift => differenceInHours(shift.period.start, new Date()) > -24);
            const shifts = getUniqueArrayList([...storeShifts, ...action.payload], 'id');
            const shiftsPerEmployee = reduceShiftsToShiftsPerEmployeeObject(shifts);

            localStorage.setItem(localStorageShiftsKey, JSON.stringify(shifts));

            return {
                ...state,
                shifts,
                shiftsPerEmployee,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): ShiftsState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setUserShifts,
    setIsLoading,
    setError,
} = ShiftsSlice.actions;

export default ShiftsSlice.reducer;
