import { FC, useState } from 'react';

import { Button, CheckSpinner, TimerClock } from '../../../components';
import { LogOutButton } from '../../../compositions';
import { formatDate, startOfDay } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { DateFormat } from '../../../types';

import './ClockOutScreen.scss';

interface ClockOutScreenProps {
    isLoading: boolean;
    serverTime: Date;
    startTime: Date;
    firstName?: string;
    onClockOutButtonClick: () => void;
    onLogOutButtonClick: () => void;
    className?: string;
}

const ClockOutScreen: FC<ClockOutScreenProps> = ({
    isLoading,
    serverTime,
    startTime,
    firstName,
    onClockOutButtonClick,
    onLogOutButtonClick,
    className = '',
}) => {
    const [initialServerTime] = useState(serverTime);

    return (
        <div className={`clock-out-screen ${className}`}>
            <h1 className="clock-out-screen__title">
                {firstName ? (
                    trans('containers.clockOutScreen.title', { firstName })
                ) : (
                    trans('containers.clockOutScreen.titleNoName')
                )}
            </h1>
            <h2 className="clock-out-screen__date">
                {`${formatDate(startTime, DateFormat.dateLabelShort)}, `}
                <TimerClock
                    startTimer
                    startTime={initialServerTime}
                    shiftTime={startOfDay(startTime)}
                    className="clock-out-screen__current-time"
                    labelClassName="clock-out-screen__current-time-label"
                />
            </h2>
            <TimerClock
                startTimer
                startTime={initialServerTime}
                shiftTime={startTime}
                className="clock-out-screen__timer"
            />
            {!isLoading ? (
                <div className="clock-out-screen__clock-out-button-wrapper">
                    <Button
                        hideLabel
                        text={trans('common.clockOut')}
                        onClick={onClockOutButtonClick}
                        className="clock-out-screen__clock-out-button"
                    />
                </div>
            ) : (
                <CheckSpinner className="clock-out-screen__check-spinner" />
            )}
            <LogOutButton
                onClick={onLogOutButtonClick}
                className="clock-out-screen__log-out-button"
            />
        </div>
    );
};

export default ClockOutScreen;
