import * as Sentry from '@sentry/browser';

import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DepartmentResource } from '../Department/Department';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { Setting, SettingResource } from './Setting';
import { transformSetting } from './SettingTransformers';

export const getSettingsApiCall = async (): Promise<FetchCollectionResult<Setting[], string>> => {
    try {
        const includes = ['department'];

        const url = generateApiUrl({
            endpoint: '/api/v2/settings',
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, { method: 'GET' });

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<SettingResource>(doc) || !doc.data) {
            Sentry.captureMessage(`getSettingsApiCall failed with status code ${response.status}`, {
                extra: {
                    source: 'getSettingsApiCall',
                    tenant: process.env.REACT_APP_TENANT || window.location.hostname,
                },
            });

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const settings = doc.data.map(settingResource => {
            const departmentResource = getIncluded(doc, settingResource, 'department') as DepartmentResource;

            return transformSetting(settingResource, departmentResource?.id);
        });

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: settings,
        };
    } catch (error) {
        console.error('[getSettingsApiCall]', error);

        Sentry.captureException(error, {
            extra: {
                source: 'getSettingsApiCall',
                tenant: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
