import * as Sentry from '@sentry/browser';

import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getSettingsApiCall } from '../../entities/Setting/SettingService';
import { TypedDispatch } from '../../store';
import { setError, setIsLoading, setSettings } from './settingsReducer';

export const fetchSettings = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const response = await getSettingsApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response));

            return;
        }

        dispatch(setSettings(response.data));
    } catch (error) {
        console.error('[fetchSettings]', error);

        Sentry.captureException(error, {
            extra: {
                source: 'fetchSettings',
                tenant: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });
    }

    dispatch(setIsLoading(false));
};
