import { FC } from 'react';

import trans from '../../../helpers/trans';

import './GoodbyeScreen.scss';

interface GoodbyeScreenProps {
    firstName?: string;
    className?: string;
}

const GoodbyeScreen: FC<GoodbyeScreenProps> = ({ firstName, className = '' }) => (
    <div className={`goodbye-screen ${className}`}>
        <h1>{firstName}</h1>
        <h1 className="goodbye-screen__goodbye">
            {trans('containers.goodbyeScreen.title')}
        </h1>
    </div>
);

export default GoodbyeScreen;
