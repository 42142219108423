import * as Sentry from '@sentry/browser';

import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getTimerUsersApiCall } from '../../entities/User/UserService';
import { TypedDispatch } from '../../store';
import { setError, setIsLoading, setTimerUsers } from './usersReducer';

export const fetchTimerUsers = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError());

    try {
        const response = await getTimerUsersApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            Sentry.captureMessage(`fetchTimerUsers failed with status code ${response.status}`, {
                extra: {
                    source: 'fetchTimerUsers',
                    tenant: process.env.REACT_APP_TENANT || window.location.hostname,
                },
            });

            dispatch(setError(response));

            return;
        }

        dispatch(setTimerUsers(response.data));
    } catch (error) {
        console.error('[fetchTimerUsers]', error);

        Sentry.captureException(error, {
            extra: {
                source: 'fetchTimerUsers',
                tenant: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });
    }

    dispatch(setIsLoading(false));
};
