import * as Sentry from '@sentry/browser';

import { getMasterCodeApiCall } from '../../entities/Authentication/AuthenticationService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../../store';
import { setIsAuthenticated, setIsFailed, setIsLoading } from './masterCodeReducer';

export const verifyMasterCode = (masterCode: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsFailed(false));

    try {
        const response = await getMasterCodeApiCall(masterCode);

        if (!isFetchResultSuccessful(response) || !response.data) {
            dispatch(setIsFailed(true));

            return;
        }

        dispatch(setIsAuthenticated(true));
    } catch (error) {
        console.error('[verifyMasterCode]', error);

        Sentry.captureException(error, {
            extra: {
                source: 'verifyMasterCode',
                tenant: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        dispatch(setIsFailed(true));
    } finally {
        dispatch(setIsLoading(false));
    }
};
