import { ReactElement } from 'react';

import { Icon } from '../../../components';
import trans from '../../../helpers/trans';
import { NumpadFunctionType } from '../../../types';

export const getNumpadIcon = (value: number | NumpadFunctionType): ReactElement => {
    if (typeof value === 'number') {
        return <span>{value}</span>;
    }

    if (value === NumpadFunctionType.submit) {
        return <span className="numpad-button__submit-text">{trans('common.next')}</span>;
    }

    return <Icon name="backspace" className="numpad-button__backspace-icon" />;
};
