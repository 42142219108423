import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import './CheckSpinner.scss';

interface CheckSpinnerProps {
    isFinished?: boolean;
    withCheck?: boolean;
    className?: string;
}

const CheckSpinner: FC<CheckSpinnerProps> = ({ isFinished, withCheck, className = '' }): ReactElement => {
    const spinnerClassNames = classNames('check-spinner__circle', {
        'check-spinner__circle--is-finished': isFinished,
    });

    const checkClassNames = classNames('check-spinner__check', {
        'check-spinner__check--is-checked': isFinished && withCheck,
    });

    return (
        <div className={`check-spinner ${className}`}>
            <div className={spinnerClassNames}>
                <div className={checkClassNames} />
            </div>
        </div>
    );
};

export default CheckSpinner;
