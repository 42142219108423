import { FC, ReactElement, useEffect } from 'react';

import SelectShiftScreen from '../../containers/@screens/SelectShiftScreen/SelectShiftScreen';
import { localStorageApiTokenKey } from '../../helpers/authorizedFetch';
import useLocalStorage from '../../helpers/hooks/useLocalStorage';
import { addCheckInTrackRecord } from '../../redux/trackRecords/trackRecordsActions';
import { setIsSuccessful } from '../../redux/trackRecords/trackRecordsReducer';
import { logout } from '../../redux/user/userReducer';
import { useTypedDispatch, useTypedSelector } from '../../store';

const ConnectedClockIn: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const [, storeApiToken] = useLocalStorage<string | undefined>(localStorageApiTokenKey, undefined);

    const { isOnline, serverApiDateTime, serverDate } = useTypedSelector(state => state.appReducer);
    const { isLoading, isSuccessful } = useTypedSelector(state => state.trackRecordsReducer);
    const { shiftsPerEmployee } = useTypedSelector(state => state.shiftsReducer);
    const { users } = useTypedSelector(state => state.usersReducer);
    const { employeeNumber } = useTypedSelector(state => state.userReducer);

    const shiftsForCurrentUser = (employeeNumber && shiftsPerEmployee[employeeNumber]) ? shiftsPerEmployee[employeeNumber] : [];
    const currentUser = users.find(user => user.employeeNumber === employeeNumber);

    useEffect((): void => {
        dispatch(setIsSuccessful(false));
    }, [employeeNumber]);

    const handleLogOutButtonClick = (): void => {
        storeApiToken(undefined);
        dispatch(logout());
    };

    const handleStartTrack = (shiftId?: string): void => {
        if (employeeNumber) {
            dispatch(addCheckInTrackRecord(
                !isOnline,
                employeeNumber,
                storeApiToken,
                serverApiDateTime,
                shiftId,
            ));
        }
    };

    return (
        <SelectShiftScreen
            isLoading={isLoading}
            isSuccessful={isSuccessful}
            serverDate={serverDate}
            shifts={shiftsForCurrentUser}
            currentUser={currentUser}
            onLogOutButtonClick={handleLogOutButtonClick}
            onStartTrack={handleStartTrack}
        />
    );
};

export default ConnectedClockIn;
