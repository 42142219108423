import { useCallback, useEffect, useState } from 'react';

import { KeyboardEventKey } from 'keyboard-event-key-type';

const useKeyUp = (): KeyboardEventKey | undefined => {
    const [key, setKey] = useState<KeyboardEventKey>();

    const onKeyUp = useCallback((e: KeyboardEvent): void => {
        setKey(e.key);
    }, [key]);

    const onKeyDown = useCallback((): void => {
        setKey(undefined);
    }, [key]);

    useEffect((): () => void => {
        document.addEventListener('keyup', onKeyUp);
        document.addEventListener('keydown', onKeyDown);

        return (): void => {
            document.removeEventListener('keyup', onKeyUp);
            document.addEventListener('keydown', onKeyDown);
        };
    }, [onKeyUp]);

    return key;
};

export default useKeyUp;
