import { FC, ReactElement } from 'react';

import { Button } from '../../components';

import './ShiftButton.scss';

interface ShiftButtonProps {
    text?: string;
    onClick: () => void;
}

const ShiftButton: FC<ShiftButtonProps> = ({
    text = '',
    onClick,
}): ReactElement => (
    <Button
        text={text}
        onClick={onClick}
        className="shift-button"
    />
);

export default ShiftButton;
