import { FC } from 'react';

import './NumpadDisplay.scss';

interface NumpadDisplayProps {
    value: string;
    className?: string;
}

const NumpadDisplay: FC<NumpadDisplayProps> = ({ value, className = '' }) => (
    <div className={`numpad-display ${className}`}>
        {value}
    </div>
);

export default NumpadDisplay;
