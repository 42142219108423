import {
    CheckInRecord,
    CheckInRecordApiBody,
    CheckOutRecord,
    CheckOutRecordApiBody,
} from './TrackRecord';
import { isCheckInRecord } from './TrackRecordHelpers';

export const transformTrackRecordsDomStringToTrackRecords = (domString: string): (CheckInRecord | CheckOutRecord)[] => {
    const trackRecords = JSON.parse(domString) as (CheckInRecord | CheckOutRecord)[];

    if (!Array.isArray(trackRecords)) {
        return [];
    }

    return trackRecords.map((trackRecord) => {
        if (isCheckInRecord(trackRecord)) {
            return {
                ...trackRecord,
                startDate: trackRecord.startDate,
            };
        }

        const checkOutRecord = trackRecord as CheckOutRecord;

        return {
            ...checkOutRecord,
            startDate: checkOutRecord.startDate,
            endDate: checkOutRecord.endDate,
        };
    });
};

export const transformCheckInTrackRecordToApiCallBody = (record: CheckInRecord): CheckInRecordApiBody => ({
    ...record,
    startDate: record.startDate,
});

export const transformCheckOutTrackRecordToApiCallBody = (record: CheckOutRecord): CheckOutRecordApiBody => ({
    ...record,
    startDate: record.startDate,
    endDate: record.endDate,
});

export const transformTrackRecordsToApiCallBody = (records: (CheckInRecord | CheckOutRecord)[]): (CheckInRecordApiBody | CheckOutRecordApiBody)[] => records.map(record => {
    if (isCheckInRecord(record)) {
        return transformCheckInTrackRecordToApiCallBody(record);
    }

    return transformCheckOutTrackRecordToApiCallBody(record);
});
