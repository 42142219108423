import { useMemo } from 'react';

import { CheckInRecord } from '../../../entities/TrackRecord/TrackRecord';
import { isCheckOutRecord } from '../../../entities/TrackRecord/TrackRecordHelpers';

interface UseFilledTrackFieldsResult {
    hasFilledHappiness: boolean;
    hasFilledMeal: boolean;
    hasFilledPause: boolean;
    hasFilledStartedWithoutShiftComment: boolean;
    hasFilledTrackDeviationRemark: boolean;
}

const useFilledTrackFields = (lastUserRecord: CheckInRecord | undefined): UseFilledTrackFieldsResult => {
    const hasFilledHappiness = useMemo(() => (
        !!(lastUserRecord && isCheckOutRecord(lastUserRecord) && lastUserRecord.happiness)
    ), [lastUserRecord]);
    const hasFilledMeal = useMemo(() => (
        !!(lastUserRecord && isCheckOutRecord(lastUserRecord) && lastUserRecord.meal)
    ), [lastUserRecord]);
    const hasFilledPause = useMemo(() => (
        !!(lastUserRecord && isCheckOutRecord(lastUserRecord) && lastUserRecord.pause !== undefined)
    ), [lastUserRecord]);
    const hasFilledStartedWithoutShiftComment = useMemo(() => (
        !!(lastUserRecord && isCheckOutRecord(lastUserRecord) && lastUserRecord.unplannedRemark)
    ), [lastUserRecord]);
    const hasFilledTrackDeviationRemark = useMemo(() => (
        !!(lastUserRecord && isCheckOutRecord(lastUserRecord) && lastUserRecord.deviationRemark)
    ), [lastUserRecord]);

    return {
        hasFilledHappiness,
        hasFilledMeal,
        hasFilledPause,
        hasFilledStartedWithoutShiftComment,
        hasFilledTrackDeviationRemark,
    };
};

export default useFilledTrackFields;
