import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { differenceInSeconds } from 'date-fns';

import useInterval from '../../helpers/hooks/useInterval';
import { getHoursMinutesSecondsFromDate, getTwoDigitsOfNumber } from '../../helpers/number';

import './TimerClock.scss';

interface TimerClockProps {
    startTimer: boolean;
    startTime: Date;
    shiftTime: Date;
    className?: string;
    labelClassName?: string;
}

const TimerClock: FC<TimerClockProps> = ({
    startTimer,
    startTime,
    shiftTime,
    className = '',
    labelClassName = '',
}): ReactElement => {
    const [timer, setTimer] = useState<Date>();

    const [hours, setHours] = useState<number>(getHoursMinutesSecondsFromDate(shiftTime, startTime).h);
    const [minutes, setMinutes] = useState<number>(getHoursMinutesSecondsFromDate(shiftTime, startTime).m);
    const [seconds, setSeconds] = useState<number>(getHoursMinutesSecondsFromDate(shiftTime, startTime).s);

    useInterval(() => {
        if (!startTimer || !timer) return;

        const timeLapsed = differenceInSeconds(new Date(), timer);

        const { h, m, s } = getHoursMinutesSecondsFromDate(shiftTime, startTime, timeLapsed);

        setHours(h);
        setMinutes(m);
        setSeconds(s);
    }, 1000, [startTimer, timer, seconds]);

    useEffect(() => {
        if (startTimer) {
            setTimer(new Date());
        }
    }, [startTimer]);

    return (
        <div className={`timer-clock ${className}`}>
            <p className={`timer-clock__label ${labelClassName}`}>
                {getTwoDigitsOfNumber(hours)}
            </p>
            <p className={`timer-clock__label ${labelClassName}`}>
                {getTwoDigitsOfNumber(minutes)}
            </p>
            <p className={`timer-clock__label ${labelClassName}`}>
                {getTwoDigitsOfNumber(seconds)}
            </p>
        </div>
    );
};

export default TimerClock;
