import { useMemo } from 'react';

import { isCheckOutRecord } from '../../../entities/TrackRecord/TrackRecordHelpers';
import { useTypedSelector } from '../../../store';
import useLastUserRecord from './useLastUserRecord';

const useIsClockedOut = (employeeNumber: number, trackId?: string): boolean => {
    const { trackRecords } = useTypedSelector(state => state.trackRecordsReducer);

    const isTrackClockedOut = useMemo((): boolean => {
        if (!trackId) {
            return false;
        }

        return trackRecords.some(trackRecord => isCheckOutRecord(trackRecord) && trackRecord.trackId === trackId);
    }, [trackId, trackRecords]);

    const { lastUserRecord } = useLastUserRecord(employeeNumber);
    const isLastUserRecordClockedOut = !!(lastUserRecord && !!isCheckOutRecord(lastUserRecord));

    return isTrackClockedOut || isLastUserRecordClockedOut;
};

export default useIsClockedOut;
