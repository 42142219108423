import { combineReducers } from 'redux';

import appReducer, { AppState } from './app/appReducer';
import masterCodeReducer, { MasterCodeState } from './masterCode/masterCodeReducer';
import settingsReducer, { SettingsState } from './settings/settingsReducer';
import shiftsReducer, { ShiftsState } from './shifts/shiftsReducer';
import trackRecordsReducer, { TrackRecordsState } from './trackRecords/trackRecordsReducer';
import tracksReducer, { TracksState } from './tracks/tracksReducer';
import userReducer, { UserState } from './user/userReducer';
import usersReducer, { UsersState } from './users/usersReducer';

export interface Reducers {
    appReducer: AppState;
    masterCodeReducer: MasterCodeState;
    userReducer: UserState;
    settingsReducer: SettingsState;
    shiftsReducer: ShiftsState;
    trackRecordsReducer: TrackRecordsState;
    tracksReducer: TracksState;
    usersReducer: UsersState;
}

export default combineReducers({
    appReducer,
    masterCodeReducer,
    userReducer,
    settingsReducer,
    shiftsReducer,
    trackRecordsReducer,
    tracksReducer,
    usersReducer,
});
