import { transformAtomDateToApiDateTime } from '../../helpers/date/transformAtomDateToApiDate';
import { ClockedInTrack, ClockedInTrackResourceV3, ClockedInTrackV3 } from './Track';

export const transformToClockedInTrackV3 = (resource: ClockedInTrackResourceV3): ClockedInTrackV3 => ({
    id: resource.id,
    startDate: transformAtomDateToApiDateTime(resource.startDate),
    userId: resource.userId,
    ...(resource.shiftId && { shiftId: resource.shiftId }),
});

export const transformClockedInTrackV3ToClockedInTrack = (
    resource: ClockedInTrackV3,
    employeeNumber: number,
): ClockedInTrack => ({
    id: resource.id,
    startDate: transformAtomDateToApiDateTime(resource.startDate),
    employeeNumber,
    shiftId: resource.shiftId,
});

export const transformTacksDomStringToClockedInTracks = (domString: string): ClockedInTrack[] => {
    const tracks = JSON.parse(domString) as ClockedInTrack[];

    return tracks.map(track => ({
        ...track,
        shiftId: track.shiftId || undefined,
        startDate: track.startDate,
    }));
};
