import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftResource } from '../Shift/Shift';
import { TemporaryWorker, TemporaryWorkerResource } from './TemporaryWorker';

export const transformToTemporaryWorker = (
    temporaryWorkerResource: TemporaryWorkerResource,
    shiftResource: ShiftResource,
): TemporaryWorker => {
    const start = temporaryWorkerResource.attributes.start || shiftResource.attributes.start;
    const end = temporaryWorkerResource.attributes.end || shiftResource.attributes.end;

    return ({
        id: temporaryWorkerResource.id,
        type: temporaryWorkerResource.type,
        name: temporaryWorkerResource.attributes.name,
        period: transformToPeriod(new Date(start), new Date(end)),
    });
};
