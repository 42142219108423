import { Period } from './Period';

export const transformToPeriod = (
    start: Date,
    end: Date,
    hasEnd?: boolean,
): Period => ({
    start,
    end,
    hasEnd,
});
