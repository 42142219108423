import {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import { CheckSpinner, TimerClock } from '../../../components';
import { LogOutButton, ShiftButton } from '../../../compositions';
import { ShiftIndex } from '../../../entities/Shift/Shift';
import { TimerUser } from '../../../entities/User/User';
import { formatDate } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { DateFormat } from '../../../types';
import { hasShiftPlanningWithin1BeforeAnd1After, hasShiftPlanningWithin4BeforeAnd2After } from './helpers/recentShiftsHelper';

import './SelectShiftScreen.scss';

interface SelectShiftScreenProps {
    isLoading: boolean;
    isSuccessful: boolean;
    serverDate: Date;
    shifts: ShiftIndex[];
    currentUser?: TimerUser;
    onLogOutButtonClick: () => void;
    onStartTrack: (shiftId?: string) => void;
}

const SelectShiftScreen: FC<SelectShiftScreenProps> = ({
    isLoading,
    isSuccessful,
    serverDate,
    shifts,
    currentUser,
    onLogOutButtonClick,
    onStartTrack,
}): ReactElement => {
    const [startTimer, setStartTimer] = useState<boolean>(false);
    const [startTime, setStartTime] = useState(new Date());

    const getShiftBlockLabel = (shift: ShiftIndex): string => {
        const matchedShiftPlanning = shift.shiftPlannings.find(shiftPlanning => shiftPlanning.user.employeeNumber === currentUser?.employeeNumber);
        const start = matchedShiftPlanning ? matchedShiftPlanning.period.start : shift.period.start;

        return `${shift.department.name} ${formatDate(start, DateFormat.hoursMinutes)}`;
    };

    const handleStartTrack = (shiftId?: string): void => {
        onStartTrack(shiftId);

        setStartTimer(true);
        setStartTime(new Date());
    };

    const shiftsShownToUser = useMemo(() => shifts.filter(shift => (
        hasShiftPlanningWithin4BeforeAnd2After(shift.shiftPlannings, serverDate, currentUser?.id)
    )), [shifts, currentUser]);
    const shiftsWithin1HourMargin = useMemo(() => shifts.filter(shift => (
        hasShiftPlanningWithin1BeforeAnd1After(shift.shiftPlannings, serverDate, currentUser?.id)
    )), [shifts, currentUser]);
    const showUnplannedShiftButton = !shiftsWithin1HourMargin.length || !shiftsShownToUser.length;

    return (
        <div className="select-shift">
            <h1 className="select-shift__title">
                {startTimer
                    ? trans('containers.selectShiftScreen.pleasantWorkDay', { firstName: currentUser?.firstName || '' })
                    : trans('containers.selectShiftScreen.whichShiftDoYouWantToStart', { firstName: currentUser?.firstName || '' })}
            </h1>

            <TimerClock
                startTimer={startTimer}
                startTime={startTime}
                shiftTime={startTime}
                className="select-shift__timer"
            />

            {(isLoading || isSuccessful) && (
                <CheckSpinner
                    isFinished={!isLoading}
                    withCheck
                    className="select-shift__check-spinner"
                />
            )}
            {!isLoading && !isSuccessful && (
                <div className="select-shift__shift-buttons-wrapper">
                    {shiftsShownToUser.map(shift => (
                        <ShiftButton
                            key={shift.id}
                            text={getShiftBlockLabel(shift)}
                            onClick={() => handleStartTrack(shift.id)}
                        />
                    ))}
                    {showUnplannedShiftButton && (
                        <ShiftButton
                            text={trans('containers.selectShiftScreen.unplannedShift')}
                            onClick={() => handleStartTrack()}
                        />
                    )}
                </div>
            )}

            <LogOutButton className="select-shift__log-out-button" onClick={onLogOutButtonClick} />
        </div>
    );
};

export default SelectShiftScreen;
