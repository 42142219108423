import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';
import { TimerUser } from '../../entities/User/User';
import { transformAuthenticatedUserToTimerUser, transformShiftsToTimerUsers } from '../../entities/User/UserTransformers';
import { Error } from '../../japi/types/Error';
import { setUserShifts } from '../shifts/shiftsReducer';
import { setAuthenticatedUser } from '../user/userReducer';
import { getUpdatedUsers } from './usersHelpers';

const localStorageUsersKey = 'users';
const localStorageUsersDomString = localStorage.getItem(localStorageUsersKey);
const localStorageUsers = localStorageUsersDomString ? JSON.parse(localStorageUsersDomString) : [];

export type UsersState = AsyncReduxState<{
    users: TimerUser[];
}>;

const initialState: UsersState = {
    ...initialAsyncReduxState,
    users: localStorageUsers,
};

export const UsersSlice = createSlice({
    name: 'usersReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): UsersState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setTimerUsers(state, action: PayloadAction<TimerUser[]>): UsersState {
            localStorage.setItem(localStorageUsersKey, JSON.stringify(action.payload));

            return {
                ...state,
                users: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): UsersState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setUserShifts, (state, action): UsersState => {
            // By intercepting shifts we can extract all the users from shiftPlanning
            const newUsers = transformShiftsToTimerUsers(action.payload);
            const users = getUpdatedUsers(state.users, newUsers);

            localStorage.setItem(localStorageUsersKey, JSON.stringify(users));

            return {
                ...state,
                users,
            };
        });

        builder.addCase(setAuthenticatedUser, (state, action): UsersState => {
            const users = getUpdatedUsers(
                state.users,
                action.payload ? [transformAuthenticatedUserToTimerUser(action.payload)] : [],
            );

            localStorage.setItem(localStorageUsersKey, JSON.stringify(users));

            return {
                ...state,
                users,
            };
        });
    },
});

export const {
    setIsLoading,
    setError,
    setTimerUsers,
} = UsersSlice.actions;

export default UsersSlice.reducer;
