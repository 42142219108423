/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconSatisfied: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 60 60" version="1.1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-322.000000, -355.000000)">
                <g transform="translate(302.000000, 334.000000)">
                    <g transform="translate(0.000000, 1.000000)">
                        <g transform="translate(20.000000, 20.000000)">
                            <path d="M30,0 C13.4582609,0 0,13.4582609 0,30 C0,46.5417391 13.4582609,60 30,60 C46.5417391,60 60,46.5417391 60,30 C60,13.4582609 46.5417391,0 30,0 Z M30,57.3913043 C14.8956522,57.3913043 2.60869565,45.1043478 2.60869565,30 C2.60869565,14.8956522 14.8956522,2.60869565 30,2.60869565 C45.1043478,2.60869565 57.3913043,14.8956522 57.3913043,30 C57.3913043,45.1043478 45.1043478,57.3913043 30,57.3913043 Z" id="Shape" fillRule="nonzero" />
                            <path d="M23.4782609,24.7826087 C23.4782609,22.6252174 21.7226087,20.8695652 19.5652174,20.8695652 C17.4078261,20.8695652 15.6521739,22.6252174 15.6521739,24.7826087 C15.6521739,26.94 17.4078261,28.6956522 19.5652174,28.6956522 C21.7226087,28.6956522 23.4782609,26.94 23.4782609,24.7826087 Z" id="Shape" fillRule="nonzero" />
                            <path d="M40.4347826,20.8695652 C38.2773913,20.8695652 36.5217391,22.6252174 36.5217391,24.7826087 C36.5217391,26.94 38.2773913,28.6956522 40.4347826,28.6956522 C42.5921739,28.6956522 44.3478261,26.94 44.3478261,24.7826087 C44.3478261,22.6252174 42.5921739,20.8695652 40.4347826,20.8695652 Z" id="Shape" fillRule="nonzero" />
                            <path d="M42.6091304,36.8543478 C42.0717391,36.3730435 41.246087,36.42 40.7673913,36.956087 C38.0465217,39.9965217 34.1230435,41.7391304 30,41.7391304 C25.8782609,41.7391304 21.9534783,39.9965217 19.2326087,36.956087 C18.7513043,36.42 17.9282609,36.3730435 17.3908696,36.8543478 C16.8534783,37.3343478 16.8091304,38.1586957 17.2891304,38.696087 C20.5043478,42.2882609 25.1373913,44.3478261 30,44.3478261 C34.863913,44.3478261 39.4969565,42.2869565 42.7108696,38.696087 C43.1908696,38.1586957 43.1452174,37.3343478 42.6091304,36.8543478 Z" id="Path" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default IconSatisfied;
/* eslint-enable max-len */
