import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import {
    ConnectedClockIn,
    ConnectedClockOut,
    ConnectedInactiveScreen,
    ConnectedLoginScreen,
} from '../../connectors';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { ClockedInTrack } from '../../entities/Track/Track';
import { CheckInRecord, CheckOutRecord } from '../../entities/TrackRecord/TrackRecord';
import { isCheckInRecord } from '../../entities/TrackRecord/TrackRecordHelpers';
import { Page } from '..';

interface TimerProps {
    isOffline: boolean;
    isUserInactive: boolean;
    employeeNumber?: number;
    pin?: string;
    shifts: ShiftIndex[];
    trackRecords: (CheckInRecord | CheckOutRecord)[];
    tracks: ClockedInTrack[];
    className?: string;
}

const Timer: FC<TimerProps> = ({
    isOffline,
    isUserInactive,
    employeeNumber,
    pin,
    shifts,
    trackRecords,
    tracks,
    className = '',
}): ReactElement => {
    const [userTrack, setUserTrack] = useState<ClockedInTrack>();
    const [lastUserRecord, setLastUserRecord] = useState<CheckInRecord | CheckOutRecord>();
    const isAuthenticated = !!(employeeNumber && pin !== undefined);

    useEffect((): void => {
        if (!employeeNumber) {
            setUserTrack(undefined);
            setLastUserRecord(undefined);

            return;
        }

        const userRecords = trackRecords.filter(trackRecord => trackRecord.employeeNumber === employeeNumber);

        setUserTrack(tracks.find(track => track.employeeNumber === employeeNumber));
        setLastUserRecord(userRecords.length ? userRecords[userRecords.length - 1] : undefined);
    }, [employeeNumber]);

    if (!isAuthenticated) {
        return (
            <Page
                isOffline={isOffline}
                className={className}
            >
                <ConnectedLoginScreen />
            </Page>
        );
    }

    if (isUserInactive) {
        return (
            <ConnectedInactiveScreen />
        );
    }

    if (userTrack || (lastUserRecord && isCheckInRecord(lastUserRecord))) {
        const shiftId = userTrack?.shiftId || lastUserRecord?.shiftId;
        const departmentId = shifts.find(shift => shift.id === shiftId)?.department.id;
        const startDate = (userTrack?.startDate || lastUserRecord?.startDate) as string;

        return (
            <Page
                isOffline={isOffline}
                className={className}
            >
                <ConnectedClockOut
                    departmentId={departmentId}
                    employeeNumber={employeeNumber}
                    shiftId={shiftId}
                    startApiDateTime={startDate}
                    trackId={userTrack?.id}
                />
            </Page>
        );
    }

    return (
        <Page
            isOffline={isOffline}
            className={className}
        >
            <ConnectedClockIn />
        </Page>
    );
};

export default Timer;
