/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconUnsatisfied: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 60 60" version="1.1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-642.000000, -354.000000)" fill="#FF1C25">
                <g transform="translate(302.000000, 334.000000)">
                    <g transform="translate(320.000000, 0.000000)">
                        <g transform="translate(20.000000, 20.000000)">
                            <path d="M30,0 C13.4582609,0 0,13.4582609 0,30 C0,46.5417391 13.4582609,60 30,60 C46.5430435,60 60,46.5417391 60,30 C60,13.4582609 46.5430435,0 30,0 Z M30,57.3913043 C14.8956522,57.3913043 2.60869565,45.1043478 2.60869565,30 C2.60869565,14.8956522 14.8956522,2.60869565 30,2.60869565 C45.1030435,2.60869565 57.3913043,14.8956522 57.3913043,30 C57.3913043,45.1043478 45.1030435,57.3913043 30,57.3913043 Z" fillRule="nonzero" />
                            <path d="M23.4782609,24.7826087 C23.4782609,22.6252174 21.7226087,20.8695652 19.5652174,20.8695652 C17.4078261,20.8695652 15.6521739,22.6252174 15.6521739,24.7826087 C15.6521739,26.94 17.4078261,28.6956522 19.5652174,28.6956522 C21.7226087,28.6956522 23.4782609,26.94 23.4782609,24.7826087 Z" fillRule="nonzero" />
                            <path d="M40.4347826,20.8695652 C38.2773913,20.8695652 36.5217391,22.6252174 36.5217391,24.7826087 C36.5217391,26.94 38.2773913,28.6956522 40.4347826,28.6956522 C42.5921739,28.6956522 44.3478261,26.94 44.3478261,24.7826087 C44.3478261,22.6252174 42.5921739,20.8695652 40.4347826,20.8695652 Z" fillRule="nonzero" />
                            <path d="M30,36.5217391 C25.1373913,36.5217391 20.5043478,38.5813043 17.2891304,42.1734783 C16.8091304,42.7108696 16.8547826,43.5352174 17.3908696,44.0152174 C17.9282609,44.4965217 18.7526087,44.4495652 19.2326087,43.9134783 C21.9534783,40.8730435 25.8782609,39.1304348 30,39.1304348 C34.1217391,39.1304348 38.0465217,40.8730435 40.7673913,43.9134783 C41.0243478,44.2017391 41.3817391,44.3478261 41.7391304,44.3478261 C42.0482609,44.3478261 42.3613043,44.2382609 42.6091304,44.0152174 C43.1452174,43.5352174 43.1908696,42.7108696 42.7108696,42.1734783 C39.4956522,38.5826087 34.863913,36.5217391 30,36.5217391 Z" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default IconUnsatisfied;
/* eslint-enable max-len */
