import { FC } from 'react';

import trans from '../../../helpers/trans';
import { IconButton } from '../../index';

import './SkipButton.scss';

interface SkipButtonProps {
    onClick?: () => void;
    className?: string;
}

const SkipButton: FC<SkipButtonProps> = ({ onClick, className = '' }) => (
    <IconButton
        icon="chevron-right"
        iconPos="right"
        text={trans('common.skip')}
        onClick={onClick}
        className={`skip-button ${className}`}
        iconClassName="skip-button__icon"
    />
);

export default SkipButton;
